import React, { useState } from "react";

type PopupProps = {
  imageUrl: string; // URL of the image to display
  onClose: () => void; // Function to handle closing the popup
  title ?:string
};

const Popup: React.FC<PopupProps> = ({ imageUrl, onClose ,title}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white rounded-lg shadow-lg max-w-[400px] w-full p-2 ">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-black p-4 "
          onClick={onClose}
        >
          ✕
        </button>
        <div className="p-2 pt-12">
          <div className=" py-2">{title}</div>
          {" "}
          <img
            src={imageUrl}
            alt="Popup"
            className="rounded-t-lg object-contain w-full "
          />
        </div>
      </div>
    </div>
  );
};

export default Popup;
