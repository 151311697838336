import React, { useEffect, useState } from "react";
import DashboardPage from "../../layout";
import { visitSummryReportList } from "../../api/employee";
import PaginationComponent from "../../components/Pagination";
import Loader from "../../components/Loader";
import Export from "../../assets/icons/Export.svg";
import Filter from "../../assets/icons/Filter.svg";
import { formatCurrentStartDate, formatCurrentEndDate } from "../../utility";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import { formatDate } from "../../utility";
import Dropdown from "../../components/Dropdown";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Close from "../../assets/close-circle.svg";
import Search from "../../assets/Search.svg";
import { useNavigate } from "react-router-dom";
import URL_MAPPING from "../../routes/constants";
import { formatNumber } from "../../utility";
import Button from "../../components/Button";

const VisitReport: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const employeesPerPage = 15;
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [employees, setEmployeesData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = useState<any>("");
  const [sortType, setSortType] = useState<any>("");
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedEmpId, setSelectedEmpId] = useState<any>("");
  const [selectedSalesOffice, setSelectedSalesOffice] = useState<any>("");
  const [region, setRegion] = useState<any>("");
  const [territoryId, setTerritoryId] = useState<any>("");
  const [routeId, setRouteId] = useState<any>("");
  const [userType, setUserType] = useState<any>("");
  const [channelId, setChannelId] = useState<any>("");
  const [regionList, setRegionList] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedSalesOffice) {
      // eslint-disable-next-line array-callback-return
      employees?.filters?.sales_offices?.map((item: any) => {
        if (selectedSalesOffice === item.sales_office_id) {
          setRegionList(item.regions);
        }
      });
    } else {
      setRegionList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSalesOffice]);

  const handleFilterClick = () => {
    setOpen(true);
  };

  const handleSearch = (e: any) => {
    setSearchQuery(e);
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      search: e,
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }

    if (channelId) {
      data["channel_ids"] = [channelId];
    }
    if (region) {
      data["regions"] = region;
    }
    if (routeId) {
      data["route_ids"] = [routeId];
    }
    if (selectedSalesOffice) {
      data["sales_office_ids"] = [selectedSalesOffice];
    }
    if (selectedEmpId) {
      data["subordinate_ids"] = [selectedEmpId];
    }
    if (selectedEmpId) {
      data["territory_ids"] = [territoryId];
    }
    if (selectedEmpId) {
      data["user_type"] = userType;
    }
    if (sortType) {
      data["sort_by"] = sortType;
    }

    const params = {
      page: currentPage,
    };
    getEmployeesData(data, params);
  };

  const handleSortFillter = (data: string) => {
    if (data === sortType) {
      setSortType("");
      handleApply("");
    } else {
      setSortType(data);
      handleApply(data);
    }

    setIsDropdownOpen(false);
  };
  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const handleClose = () => setOpen(false);
  const handleClearFilters = () => {
    setSelectedEmpId(null);
    setSelectedSalesOffice(null);
    setRegion(null);
    setTerritoryId(null);
    setRouteId(null);
    setUserType(null);
    setChannelId(null);
    setStartDate(getCurrentDate());
    setEndDate(getCurrentDate());
    setSelectTimeFrame("Today");
    setOpen(false);
    handleClearApply("");
  };
  const handleClearApply = (sort: string) => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    const params = {
      page: currentPage,
    };

    getEmployeesData(data, params);
    handleClose();
  };

  const handleExport = async () => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      export: true,
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }

    if (channelId) {
      data["channel_ids"] = [channelId];
    }
    if (region) {
      data["regions"] = [region];
    }
    if (routeId) {
      data["route_ids"] = [routeId];
    }
    if (selectedSalesOffice) {
      data["sales_office_ids"] = [selectedSalesOffice];
    }
    if (selectedEmpId) {
      data["subordinate_ids"] = [selectedEmpId];
    }
    if (territoryId) {
      data["territory_ids"] = [territoryId];
    }
    if (userType) {
      data["user_type"] = userType;
    }
    if (sortType) {
      data["sort_by"] = sortType;
    }

    const params = {};
    exportActiviyData(data, params);
  };

  const exportActiviyData = async (data: any, params: any) => {
    await visitSummryReportList(params, data)
      .then((res) => {
        console.log(res, "response");
        const url = window.URL.createObjectURL(new Blob([res]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `team_visit_report.csv`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
      });
  };
  const getEmployeesData = async (data: any, params: any) => {
    setLoading(true);
    await visitSummryReportList(params, data)
      .then((res) => {
        console.log(res, "response");

        setEmployeesData(res);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
        setLoading(false);
      });
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  useEffect(() => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (selectedEmpId) {
      data["emp_id"] = selectedEmpId;
    }

    if (selectedSalesOffice) {
      data["sales_office_id"] = selectedSalesOffice;
    }

    if (status) {
      data["status"] = status;
    }

    const params = {
      page: currentPage,
      size: employeesPerPage,
    };
    getEmployeesData(data, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleApply = (sort: string) => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }

    if (channelId) {
      data["channel_ids"] = [channelId];
    }
    if (region) {
      data["regions"] = [region];
    }
    if (routeId) {
      data["route_ids"] = [routeId];
    }
    if (selectedSalesOffice) {
      data["sales_office_ids"] = [selectedSalesOffice];
    }
    if (selectedEmpId) {
      data["subordinate_ids"] = [selectedEmpId];
    }
    if (territoryId) {
      data["territory_ids"] = [territoryId];
    }
    if (userType) {
      data["user_type"] = userType;
    }
    if (sort) {
      data["sort_by"] = sort;
    }
    const params = {
      page: currentPage,
    };

    getEmployeesData(data, params);
    handleClose();
  };

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month till date");
    } else {
      setSelectTimeFrame("");
    }
  };

  return (
    <DashboardPage>
      <div className="bg-gray-50  sm:pl-0 pr-4 ">
        <div className="hidden mb-2 sm:flex flex-col sm:flex-row justify-between items-center bg-white p-2 border-b-2">
          <div className="w-full sm:w-auto mb-4 sm:mb-0 pl-6">
            <PaginationComponent
              totalCount={employees?.total}
              itemsPerPage={employees?.size}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="relative w-full sm:w-auto">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                {/* Add your search icon here */}
                <img src={Search} alt="search" />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder={t("planogram.searchPlaceholder")}
                className="w-full sm:w-auto pl-10 pr-4 py-2 text-16 border rounded text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
            </div>

            {/* Buttons group for desktop */}
            <div className="hidden sm:flex space-x-4 items-center">
              {/* <button
                onClick={handleSort}
                className="flex items-center px-4 py-2 text-16 text-gray-600 rounded transition-all"
              >
                <img src={Sort} alt="Sort Icon" className="w-4 h-4 mr-2" />
                Sort by
              </button> */}
              {isDropdownOpen && (
                <ul className="absolute z-50 right-30 top-10 w-48 bg-white border border-gray-300 rounded shadow-lg">
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                      sortType === "ASC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("ASC")}
                  >
                    {t("planogram.ascending")}
                  </li>
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer  ${
                      sortType === "DESC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("DESC")}
                  >
                    {t("planogram.descending")}
                  </li>
                </ul>
              )}
              <button
                onClick={handleFilterClick}
                className="flex items-center gap-2 px-4 py-2 text-13 font-normal rounded transition-all"
              >
                <img src={Filter} alt={t("planogram.filter")} className="w-4 h-4 mr-2" />
                {t("planogram.filter")}
              </button>

              <button
                onClick={handleExport}
                className="flex items-center gap-2 px-4 py-2 text-13 font-medium rounded border transition-all"
              >
                {t("planogram.export")}
                <img src={Export} alt={t("planogram.export")} className="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex justify-between items-center p-2 border-b-2 bg-white">
          <div className="flex-grow">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t("planogram.searchPlaceholder")}
              className="w-full px-4 py-2 text-13 border rounded text-gray-600"
              style={{ borderColor: "#e0e0e0" }}
            />
          </div>

          {/* Hamburger menu for Sort, Filter, Export */}
          <div className="flex items-center space-x-2">
            <button
              onClick={handleExport}
              className="px-2 py-2 text-gray-600 rounded transition-all"
            >
              <img src={Export} alt="Export Icon" className="w-4 h-4" />
            </button>

            <button
              className="px-2 py-2 text-gray-600 rounded transition-all"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        {open && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
    <div className="bg-white shadow-lg w-full max-w-md h-full">
      <div className="flex justify-between p-6 border-b-2">
        <div className="text-16 font-bold">{t("visitreports.filters.title")}</div>
        <div>
          <img
            className="cursor-pointer"
            onClick={() => setOpen(false)}
            src={Close}
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-col justify-between h-[calc(100vh-100px)] px-12 pt-6 overflow-y-auto">
        <div className="flex flex-col gap-[30px]">
          <div>
            <Dropdown
              label={t("visitreports.filters.employee")}
              options={
                employees?.filters?.employees.map((emp: any) => ({
                  value: emp.emp_id,
                  label: emp.emp_id,
                })) || []
              }
              selectedValue={selectedEmpId}
              onChange={setSelectedEmpId}
              placeholder={t("visitreports.filters.allEmployees")}
              searchable={true}
            />
          </div>

          {/* Sales Office and Region */}
          <div className="flex space-x-4">
            <div className="w-1/2">
              <Dropdown
                label={t("visitreports.filters.salesOffice")}
                options={
                  employees?.filters?.sales_offices.map((sales: any) => ({
                    value: sales.sales_office_id,
                    label: sales.name,
                  })) || []
                }
                selectedValue={selectedSalesOffice}
                onChange={setSelectedSalesOffice}
                placeholder={t("visitreports.filters.selectSalesOffice")}
              />
            </div>

            <div className="w-1/2">
              <Dropdown
                label={t("visitreports.filters.region")}
                options={regionList.map((sales: any) => ({
                  value: sales,
                  label: sales,
                }))}
                selectedValue={region}
                onChange={setRegion}
                placeholder={t("visitreports.filters.selectRegion")}
              />
            </div>
          </div>

          <div className="flex space-x-4">
            <div className="w-1/2">
              <Dropdown
                label={t("visitreports.filters.territoryID")}
                options={
                  employees?.filters?.territory_routes?.map((sales: any) => ({
                    value: sales.territory_id,
                    label: sales.territory_id,
                  })) || []
                }
                selectedValue={territoryId}
                onChange={setTerritoryId}
                placeholder={t("visitreports.filters.selectTerritoryID")}
              />
            </div>

            <div className="w-1/2">
              <Dropdown
                label={t("visitreports.filters.routeID")}
                options={
                  employees?.filters?.territory_routes?.map((sales: any) => ({
                    value: sales.territory_id,
                    label: sales.territory_id,
                  })) || []
                }
                selectedValue={routeId}
                onChange={setRouteId}
                placeholder={t("visitreports.filters.selectRouteID")}
              />
            </div>
          </div>

          <div className="flex space-x-4">
            <div className="w-1/2">
              <Dropdown
                label={t("visitreports.filters.userType")}
                options={[
                  {
                    value: "SALESMAN",
                    label: t("activityreports.filters.salesman"),
                  },
                  {
                    value: "PRESELLER",
                    label: t("activityreports.filters.preseller"),
                  },
                ]}
                selectedValue={userType}
                onChange={setUserType}
                placeholder={t("visitreports.filters.selectUserType")}
              />
            </div>

            <div className="w-1/2">
              <Dropdown
                label={t("visitreports.filters.channelID")}
                options={
                  employees?.filters?.channels?.map((emp: any) => ({
                    value: emp.channel_id,
                    label: emp.channel_id,
                  })) || []
                }
                selectedValue={channelId}
                onChange={setChannelId}
                placeholder={t("visitreports.filters.selectChannelID")}
              />
            </div>
          </div>

          {/* Creation Date */}
          <div className="date-range-picker space-y-2">
            <div className="flex space-x-4 justify-between w-full">
              <div className="w-full">
                <label className="block text-10 text-gray-700 mb-1">
                  {t("visitreports.filters.startDate")}:
                </label>
                <input
                  type="date"
                  className="rounded p-2 border-2 border-gray-400 w-full"
                  max={new Date().toISOString().split("T")[0]}
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div className="w-full">
                <label className="block text-10 text-gray-700 mb-1">
                  {t("visitreports.filters.endDate")}:
                </label>
                <input
                  type="date"
                  className="rounded p-2 border-2 border-gray-400 w-full"
                  max={new Date().toISOString().split("T")[0]}
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
          </div>

          <div>
            <TimeFrameSelector
              customClass={"w-full border border-gray-300 rounded p-2"}
              setSelectTimeFrame={setSelectTimeFrame}
              timeFrame={timeFrame}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between mt-4">
          <Button
            onClick={handleClearFilters}
            label={t("visitreports.filters.clearFilter")}
            type="grey"
          />
          <Button
            onClick={() => handleApply(sortType)}
            label={t("visitreports.filters.applyFilter")}
            type="purple"
          />
        </div>
      </div>
    </div>
  </div>
)}


<div className="h-[calc(100vh-200px)] md:h-[calc(100vh-125px)] overflow-auto page-container p-2 pt-0 scrollbar scrollbar-thin">
      <table className="min-w-full bg-white border border-black-200 table-auto border-separate border-spacing-0">
        <thead className="bg-gray-200 sticky top-[0px] z-20">
          <tr>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
              <span className="flex items-center gap-2 h-12 cursor-pointer">
                {t("visitreports.salesmanId")}
                <div onClick={() => handleSortFillter("Id")}>
                  <svg
                    className="w-4 h-9"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg>
                </div>
              </span>
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              <span className="flex items-center gap-2 h-12 cursor-pointer">
                {t("visitreports.salesmanName")}
                <div onClick={() => handleSortFillter("Name")}>
                  <svg
                    className="w-4 h-9"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg>
                </div>
              </span>
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.salesOffice")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.region")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.totalVisits")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.countOfOrders")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.uniqueCustomerOrders")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.orderWithVisit")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.orderWithoutVisit")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.collectionVisit")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.collectedAmount")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.visitsWithImagesOnly")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.totalCodOrders")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.totalCodOrdersValue")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.codOrdersWithQuantity")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.totalOrdersValue")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.totalCancelledOrder")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.cancelledOrderQuantityUnit")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.cancelledOrderValue")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.totalPendingOrder")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.pendingOrderQuantityUnit")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.pendingOrderQuantityCar")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.pendingOrderValue")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.totalDeliveredOrder")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.totalDeliveredOrderQuantity")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.deliveredOrderValue")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
              {t("visitreports.sold200mlQuantity")}
            </th>
            <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.sold330mlQuantity")}
      </th>
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.sold600mlQuantity")}
      </th>
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.sold500mlQuantity")}
      </th>
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.sold5_12ltrQuantity")}
      </th>
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.sold1_5ltrQuantity")}
      </th>
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.soldGlassQuantity")}
      </th>
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.soldOtherItemsQuantity")}
      </th>
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.totalUniqueItemsSold")}
      </th>
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.beginDay")}
      </th>
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.endDay")}
      </th>
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.totalTime")}
      </th>
      {/* <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("clockInTime")}
      </th>
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("clockOutTime")}
      </th> */}
      <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
        {t("visitreports.codOrderPercentage")}
      </th>
              </tr>
            </thead>
            <tbody>
              {employees?.items?.length > 0 &&
                employees.items.map((employee: any) => (
                  <tr
                    key={employee.emp_id}
                    className="text-16 cursor-pointer	hover:bg-gray-50"
                    onClick={() =>
                      navigate(URL_MAPPING.ANALYTICS, {
                        state: employee.salesman_id,
                      })
                    }
                  >
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.salesman_id}
                    </td>
                    {/* <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.status || "N/A"}
                    </td> */}
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.salesman_name_eng || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sales_office_name || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sales_office_region || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_unique_customers_order_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_with_visits_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_without_visits_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_collection_visits_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {formatNumber(employee.total_amount_collected || 0)}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_visits_with_images_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cod_orders_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cod_orders_value || 0}
                    </td>

                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cod_orders_quantity_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cancelled_orders_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cancelled_orders_quantity_units_count ||
                        0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cancelled_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_pending_orders_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_pending_orders_quantity_units_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_pending_orders_quantity_cartons_count ||
                        0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_pending_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_delivered_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_delivered_orders_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_delivered_orders_value}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_sold_quantity_200ml_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_sold_quantity_330ml_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_sold_quantity_600ml_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_sold_quantity_500ml_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_sold_quantity_5_by_12_ltr_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_sold_quantity_1_half_ltr_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_sold_quantity_glass_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_sold_quantity_other_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_unique_items_sold_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.clock_in_time
                        ? moment
                            .utc(employee.clock_in_time, "HH:mm:ss.SSSSSS")
                            .local()
                            .format("hh:mm A")
                        : "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.clock_out_time
                        ? moment
                            .utc(employee.clock_out_time, "HH:mm:ss.SSSSSS")
                            .local()
                            .format("hh:mm A")
                        : "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_time_spent_hours || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.cod_percentage || 0}
                    </td>
                    {/* <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_visits_count || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_planned_visits || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.unique_customers_with_orders || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_with_visits || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_without_visits || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_collection_visits || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.amount_collected || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.failed_visits || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cash_on_delivery_orders || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.cash_on_delivery_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.cash_on_delivery_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.cash_on_delivery_orders_quantity || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_quantity || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cancelled_orders || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.cancelled_orders_quantity_units || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cancelled_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_pending_orders || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.pending_orders_quantity_units || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.pending_orders_quantity_cartons || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_pending_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_delivered_orders || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.delivered_orders_quantity_cartons || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_delivered_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_200ml || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_330ml || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_600ml || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_500ml || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_5_by_12_ltr || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_1_half_ltr || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_glass || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_other || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_unique_items_sold || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.begin_day_time
                        ? moment
                            .utc(employee.begin_day_time, "HH:mm:ss.SSSSSS")
                            .local()
                            .format("hh:mm A")
                        : "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.end_day_time
                        ? moment
                            .utc(employee.end_day_time, "HH:mm:ss.SSSSSS")
                            .local()
                            .format("hh:mm A")
                        : "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_time_spent_hours || 0}
                    </td>
                   
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.cash_on_delivery_percentage || 0}
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
          {employees?.items?.length === 0 && !loading && (
            <>
              <div className="px-4 py-3 border text-center text-black-600 w-full flex items-center justify-center">
              {t("planogram.noresultsfound")}
              </div>
            </>
          )}
          {loading && <Loader />}
        </div>
        <div className="sm:hidden bg-white p-2 border-t-2 mt-auto">
          <PaginationComponent
            totalCount={employees?.total}
            itemsPerPage={employees?.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </DashboardPage>
  );
};

export default VisitReport;
