import React from "react";

interface LoaderButtonProps {
  text: string;
  onClick: any;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  isLoading: boolean;
}

const LoaderButton: React.FC<LoaderButtonProps> = ({
  text,
  onClick,
  type = "button",
  disabled = false,
  isLoading,
}) => {
  const disabledStyles = "opacity-50 cursor-not-allowed bg-black";

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <button
      type={type}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      className={`w-full bg-purple text-white py-2 rounded-md h-12 flex items-center hover:bg-culightpurple	 justify-center ${
        isLoading ? disabledStyles : ""
      }`}
      disabled={disabled}
    >
      {isLoading ? (
        <svg
          className="animate-spin h-5 w-5 text-white "
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
      ) : (
        text
      )}
    </button>
  );
};

export default LoaderButton;
