import Cookies from "js-cookie";
import axios from "./config";
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  emp_id: string;
}

export function getHeader() {
  const token = Cookies.get("token") || "";
  const header = {
    Authorization: token,
  };
  return header;
}

const getUserInfo = () => {
  const token = Cookies.get("token");

  if (!token) {
    throw new Error("Authorization token not found");
  }

  return jwtDecode<DecodedToken>(token);
};

const getPlanogramList = async (params: any, data: any): Promise<any> => {
  const user_info = getUserInfo();
  console.log("Request Params:", params);

  const response = await axios.post<any>(
    `/reports/${user_info.emp_id}/planogram-report`,
    data,
    {
      headers: getHeader(),
      params: params,
    }
  );
  return response.data;
};

export default getPlanogramList;
