import React from "react";

interface MetricBarProps {
  text?: string;
  count: string;
  maxCount: number;
  color: string;
  bgcolor?: string;
  number?: string;
}

const MetricBar: React.FC<MetricBarProps> = ({
  text,
  count,
  maxCount,
  color,
  bgcolor,
  number,
}) => {
  const widthPercentage = (Number(count) / maxCount) * 100;

  return (
    <li className="flex justify-between items-center border-b-2 pb-2">
      <div className="flex gap-6">
        <div className="text-[13px] font-normal text-[#4A4A4A]">{number}</div>
        <span className="text-[13px] font-normal text-[#4A4A4A]">
          {text}
        </span>{" "}
      </div>
      <div className="flex items-center gap-6 w-[60%] justify-end">
        <div
          className="h-2 rounded-full"
          style={{
            width: `${widthPercentage}%`,
            backgroundColor: color,
          }}
        ></div>
        <span
          className={`font-bold text-lg border rounded-md px-6 py-1 w-full max-w-[70px] min-w-[35px] flex items-center justify-center`}
          style={{
            color: color,
            borderColor: color,
            backgroundColor: bgcolor,
          }}
        >
          {count}
        </span>
      </div>
    </li>
  );
};

export default MetricBar;
