import React, { useEffect, useState } from "react";
import DashboardPage from "../../layout";
import getCustomerList from "../../api/masterList";
import PaginationComponent from "../../components/Pagination";
import {
  formatCurrentStartDate,
  formatCurrentEndDate,
  formatNumber,
} from "../../utility";
import Export from "../../assets/icons/Export.svg";
import Filter from "../../assets/icons/Filter.svg";
import Loader from "../../components/Loader";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import { formatDate } from "../../utility";
import Close from "../../assets/close-circle.svg";
import Dropdown from "../../components/Dropdown";
import Search from "../../assets/Search.svg";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";

const CustomerTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const customersPerPage = 15;
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [customers, setCustomersData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [status, setStatus] = useState<any>("");
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [channel, setChannel] = useState<any>("");
  const [division, setDivision] = useState<any>("");
  const [salesOffice, setSalesOffice] = useState<any>("");
  const [emp, setEmployee] = useState<any>("");
  const [route, setRouteID] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [regions, setRegions] = useState<any>("");
  const [paymentTermId, setPaymentTermId] = useState<any>("");
  const [sortType, setSortType] = useState<any>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [regionList, setRegionList] = useState<any>();

  const handleFilterClick = () => {
    setOpen(true);
  };
  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const handleClearFilters = () => {
    setRouteID(null)
    setEmployee(null); 
    setSalesOffice(null);
    setDivision(null); 
    setChannel(null);
    setStatus(null); 
    setStartDate(getCurrentDate()); 
    setEndDate(getCurrentDate()); 
    setOpen(false);
    setSelectTimeFrame("Today");
    handleClearApply("");
  };


  const handleClearApply = (sort: string) => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    const params = {
      page: currentPage,
    };

    getCustomerData(data, params);
    handleClose();
  };

  const handleSearch = (e: any) => {
    setSearchQuery(e);
    const data = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      search: e,
    };
    const params = {
      page: currentPage,
    };
    getCustomerData(data, params);
  };

  const handleSortFillter = (data: string) => {
    setSortType(data);
    setIsDropdownOpen(false);
    handleApply(data);
  };

  const handleClose = () => setOpen(false);

  const getCustomerData = async (data: any, params: any) => {
    setCustomersData([]);
    setLoading(true);
    await getCustomerList(params, data)
      .then((res) => {
        setLoading(false);
        setCustomersData(res);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Customer data:", error);
      });
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  useEffect(() => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (status) {
      data["status"] = status;
    }
    if (channel) {
      data["channel_ids"] = [channel];
    }
    if (division) {
      data["division_ids"] = [division];
    }
    if (emp) {
      data["customer_ids"] = [emp];
    }
    if (regions) {
      data["regions"] = [regions];
    }
    if (salesOffice) {
      data["sales_office_ids"] = [salesOffice];
    }
    if (paymentTermId) {
      data["payment_term_ids"] = [paymentTermId];
    }
    if (route) {
      data["route_ids"] = [route];
    }
    if (sortType) {
      data["sort_by"] = sortType;
    }

    const params = {
      page: currentPage,
      size: customersPerPage,
    };
    getCustomerData(data, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month till date");
    } else {
      setSelectTimeFrame("");
    }
  };

  const handleApply = (sort: string) => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (status) {
      data["status"] = status;
    }
    if (channel) {
      data["channel_ids"] = [channel];
    }
    if (division) {
      data["division_ids"] = [division];
    }
    if (emp) {
      data["customer_ids"] = [emp];
    }
    if (regions) {
      data["regions"] = [regions];
    }
    if (salesOffice) {
      data["sales_office_ids"] = [salesOffice];
    }
    if (sort) {
      data["sort_by"] = sort;
    }
    if (paymentTermId) {
      data["payment_term_ids"] = [paymentTermId];
    }
    if (route) {
      data["route_ids"] = [route];
    }
    const params = {
      page: currentPage,
    };
    getCustomerData(data, params);
    handleClose();
  };

  const handleExport = async () => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      export: true,
    };

    if (status) {
      data["status"] = status;
    }
    if (channel) {
      data["channel_ids"] = [channel];
    }
    if (division) {
      data["division_ids"] = [division];
    }
    if (emp) {
      data["customer_ids"] = [emp];
    }
    if (regions) {
      data["regions"] = [regions];
    }
    if (salesOffice) {
      data["sales_office_ids"] = [salesOffice];
    }
    if (sortType) {
      data["sort_by"] = sortType;
    }
    if (paymentTermId) {
      data["payment_term_ids"] = [paymentTermId];
    }
    if (route) {
      data["route_ids"] = [route];
    }
    const params = {};
    exportActiviyData(data, params);
  };

  const exportActiviyData = async (data: any, params: any) => {
    await getCustomerList(params, data)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `master_report.csv`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
      });
  };
  useEffect(() => {
    if (salesOffice) {
      // eslint-disable-next-line array-callback-return
      customers?.filters?.sales_offices?.map((item: any) => {
        if (salesOffice === item.sales_office_id) {
          setRegionList(item.regions);
        }
      });
    } else {
      setRegionList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesOffice]);
  return (
    <DashboardPage>
      <div className="bg-gray-50 pl-[10px] lg:pl-[15px] md:pl-[15px] sm:pl-0 pr-4">
        <div className="hidden mb-2 sm:flex flex-col sm:flex-row justify-between items-center bg-white p-2 border-b-2">
          <div className="w-full sm:w-auto mb-4 sm:mb-0 pl-6">
            <PaginationComponent
              totalCount={customers?.total}
              itemsPerPage={customers?.size}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="relative w-full sm:w-auto">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={Search} alt={t("masterList.searchPlaceholder")} />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder={t("masterList.searchPlaceholder")}
                className="w-full sm:w-auto pl-10 pr-4 py-2 text-sm border rounded text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
            </div>
            <div className="hidden sm:flex space-x-4 items-center relative">
              {/* <button
                onClick={handleSort}
                className="flex items-center px-4 py-2 text-sm text-gray-600 rounded transition-all"
              >
                <img src={Sort} alt="Sort Icon" className="w-4 h-4 mr-2" />
                Sort by
              </button> */}
              {isDropdownOpen && (
                <ul className="absolute z-50 right-30 top-10 w-48 bg-white border border-gray-300 rounded shadow-lg">
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${sortType === "ASC" && "bg-lightgrey"
                      }`}
                    onClick={() => handleSortFillter("ASC")}
                  >
                    {t("masterList.ascending")}
                  </li>
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer  ${sortType === "DESC" && "bg-lightgrey"
                      }`}
                    onClick={() => handleSortFillter("DESC")}
                  >
                    {t("masterList.descending")}
                  </li>
                </ul>
              )}
              <button
                onClick={handleFilterClick}
                className="flex items-center px-4 py-2 text-13  rounded transition-all"
              >
                <img src={Filter} alt={t("masterList.filter")} className="w-4 h-4 mr-2" />
                {t("masterList.filter")}
              </button>

              <button
                onClick={handleExport}
                className="flex items-center px-4 py-2 text-13 font-medium border rounded transition-all"
              >
                {t("masterList.export")}
                <img src={Export} alt={t("masterList.export")} className="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex justify-between items-center p-2 border-b-2 bg-white">
          <div className="relative w-full sm:w-auto">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              {/* Add your search icon here */}
              <img src={Search} alt={t("masterList.searchPlaceholder")} />
            </div>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={t("masterList.searchPlaceholder")}
              className="w-full sm:w-auto pl-10 pr-4 py-2 text-sm border rounded text-gray-600"
              style={{ borderColor: "#e0e0e0" }}
            />
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handleExport}
              className="px-2 py-2 text-gray-600 rounded transition-all"
            >
              <img src={Export} alt={t("masterList.export")} className="w-4 h-4" />
            </button>
            <button
              className="px-2 py-2 text-gray-600 rounded transition-all"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
            <div className="bg-white shadow-lg w-full max-w-md h-full">
              <div className="flex justify-between p-6 border-b-2">
                <div className="text-16 font-bold">{t("masterList.filters.title")}</div>
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                    src={Close}
                    alt={t("masterList.filters.title")}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between h-[calc(100vh-100px)] px-12 pt-6">
                <div className="flex flex-col gap-[30px]">
                  {/* Employee ID */}
                  <div>
                    <Dropdown
                      label={t("masterList.filters.customerID")}
                      options={
                        customers?.filters?.customers?.map((emp: any) => ({
                          value: emp.customer_id,
                          label: emp.customer_id,
                        })) || []
                      }
                      selectedValue={emp}
                      onChange={setEmployee}
                      placeholder={t("masterList.filters.selectCustomerID")}
                    />
                  </div>

                  {/* Sales Office and Region */}
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <Dropdown
                        label={t("masterList.filters.salesOffice")}
                        options={
                          customers?.filters?.sales_offices?.map(
                            (emp: any) => ({
                              value: emp.sales_office_id,
                              label: emp.name,
                            })
                          ) || []
                        }
                        selectedValue={salesOffice}
                        onChange={setSalesOffice}
                        placeholder={t("masterList.filters.selectSalesOffice")}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label={t("masterList.filters.region")}
                        options={
                          regionList?.map((emp: any) => ({
                            value: emp,
                            label: emp,
                          })) || []
                        }
                        selectedValue={regions}
                        onChange={setRegions}
                        placeholder={t("masterList.filters.selectRegion")}
                      />
                    </div>
                  </div>

                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <Dropdown
                        label={t("masterList.filters.paymentTermID")}
                        options={
                          customers?.filters?.payment_terms?.map(
                            (emp: any) => ({
                              value: emp.term_id,
                              label: emp.name,
                            })
                          ) || []
                        }
                        selectedValue={paymentTermId}
                        onChange={setPaymentTermId}
                        placeholder={t("masterList.filters.selectID")}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label={t("masterList.filters.route")}
                        options={
                          customers?.filters?.territory_routes?.map(
                            (emp: any) => ({
                              value: emp.routes,
                              label: emp.territory_id,
                            })
                          ) || []
                        }
                        selectedValue={route}
                        onChange={setRouteID}
                        placeholder={t("masterList.filters.selectRoute")}
                      />
                    </div>
                  </div>

                  {/* channel  and Status */}
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <Dropdown
                        label={t("masterList.filters.channels")}
                        options={
                          customers?.filters?.channels?.map((emp: any) => ({
                            value: emp.channel_id,
                            label: emp.name,
                          })) || []
                        }
                        selectedValue={channel}
                        onChange={setChannel}
                        placeholder={t("masterList.filters.selectChannel")}
                      />
                    </div>

                    <div className="w-1/2">
                      <Dropdown
                        label={t("masterList.filters.status")}
                        options={[
                          { value: "A", label: "Active" },
                          { value: "I", label: "Inactive" },
                        ]}
                        selectedValue={status}
                        onChange={setStatus}
                        placeholder={t("masterList.filters.selectStatus")}
                      />
                    </div>
                  </div>

                  {/* Creation Date */}
                  <div className="date-range-picker flex gap-4">
                    <div className="flex gap-4 w-full">
                      <div className="w-full">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("masterList.filters.startDate")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={startDate}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={handleStartDateChange}
                        />
                      </div>
                      <div className="w-full">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("masterList.filters.endDate")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={endDate}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={handleEndDateChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                    <TimeFrameSelector
                      customClass={"w-full border border-gray-300 rounded p-2"}
                      setSelectTimeFrame={setSelectTimeFrame}
                      timeFrame={timeFrame}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>

                <div className="flex justify-between mt-4">
                  <Button
                    onClick={handleClearFilters}
                    label={t("masterList.filters.clearFilters")}
                    type="grey"
                  />
                  <Button
                    onClick={() => handleApply(sortType)}
                    label={t("masterList.filters.applyFilters")}
                    type="purple"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          // id="printable-area"
          className="h-[calc(100vh-200px)] md:h-[calc(100vh-120px)] p-2 pt-0 overflow-auto  page-container scrollbar scrollbar-thin "
          style={{
            overflow: customers?.items?.length === 0 ? "hidden" : "auto",
          }}
        >
          <table className="min-w-full bg-white border border-black-200 table-auto border-separate border-spacing-0">
            <thead className="bg-gray-200 sticky top-[0px] z-20">
              <tr>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.territoryID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.routeID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.outletID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.employeeName")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.customerName")}
                </th>
                {/* <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.customerArabicName")}
                </th> */}
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.payerID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.cusStatus")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.paytermBlocked")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.blockedDate")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.blockTime")}
                </th>


                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.commericalRegisterNumber")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.entryDate")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.validFrom")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.validTo")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.promissory")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.promNoteEntryDate")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.promNoteValidFrom")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.promNoteValidTo")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.building")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.city")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.district")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.postalCode")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.street")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.createdOn")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.salesOrganization")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.distributionChannel")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.distChannelText")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.division")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.divisionText")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.paymentTerms")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.paymentTermsText")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.customerTaxCode")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.salesOffice")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.creditLimit")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.receivables")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.avaiableLimit")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.CreditLimitDeciation")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.presellerID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.presellerName")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.supervisorID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.supervisorName")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.teamleadID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.teamleadName")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.longitude")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.latitude")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.vatNumber")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.lastLocationCaptureAuditedDate")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.lastAuditedTime")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.auditedByUser")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.customerVirtualAccount")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.iban")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.comment1")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.comment2")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.brandName")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.lastInvoiceDate")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("masterList.tableHeaders.lastOrderDate")}
                </th>

              </tr>
            </thead>
            <tbody>
              {customers?.items?.length > 0 &&
                customers.items.map((customer: any) => (
                  <tr key={customer.emp_id} className="hover:bg-gray-50">
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.territory_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.route_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.outlet_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.emp_name || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {i18n.language === "ar" ? customer.customer_name_arb: customer.customer_name_eng}
                    </td>
                    {/* <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.customer_name_arb || "-"}
                    </td> */}
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.payer_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.customer_status || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.payterm_blocked || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.blocked_date || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.blocked_time || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.commercial_register_number || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.entry_date || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.valid_from || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.valid_to || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.promissory || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.prom_note_entry_date || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.prom_note_valid_from || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.prom_note_valid_to || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.building || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.city || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.district || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.postal_code || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.street || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.created_on || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.sales_organization || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.distribution_channel || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.dist_channel_text || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.division || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.division_text || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.payment_terms || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.payment_terms_text || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.customer_tax_code || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.sales_office || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {formatNumber(customer.credit_limit || 0)}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.receivables || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.available_limit || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.credit_limit_deviation || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.preseller_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.preseller_name || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.supervisor_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.supervisor_name || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.teamlead_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.teamlead_name || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.longitude || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.latitude || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.vat_number || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_location_capture_audited_date || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_audited_time || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.audited_by_user || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.customer_virtual_account || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.iban || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.comment_1 || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.comment_2 || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.brand_name || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_invoice_date || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_order_date || "-"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {customers?.items?.length === 0 && !loading && (
            <>
              <div className="px-4 py-3 border text-center text-black-600 w-full flex items-center justify-center">
                {t("masterList.noresultsfound")}
              </div>
            </>
          )}
          {loading && <Loader />}
        </div>
        <div className="sm:hidden bg-white p-2 border-t-2 mt-auto">
          <PaginationComponent
            totalCount={customers?.total}
            itemsPerPage={customers?.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </DashboardPage>
  );
};

export default CustomerTable;
