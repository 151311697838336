import axios from "./config";
import { getHeader, getUserInfo } from "../utility/index";

interface ApiResponse {
  data: any;
}

const approveRequest = async (id: Number, data: any): Promise<ApiResponse> => {
  const response = await axios.post<ApiResponse>(
    `/supervisors/${id}/action`,
    data,
    {
      headers: getHeader(),
    }
  );
  return response;
};
const getAllNotification = async (): Promise<ApiResponse> => {
  const user_info=getUserInfo()
  const response = await axios.get<ApiResponse>(
    `supervisors/notifications/${user_info.emp_id}/all`,
    {
      headers: getHeader(),
    }
  );
  return response;
};
export default approveRequest;
export {getAllNotification}
