import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import URL_MAPPING from "../routes/constants";
import EmpList from "../assets/icons/emp-list.svg";
import EmpListDark from "../assets/icons/emp-list-dark.svg";
import MapView from "../assets/icons/map-view.svg";
import MapViewDark from "../assets/icons/map-view-dark.svg";
import Home from "../assets/icons/home-icon.svg";
import Dashboard from "../assets/icons/dashboard.svg";
import DashboardDark from "../assets/icons/dashboard-dark.svg";
import VisitReport from "../assets/icons/visit-report.svg";
import VisitReportDark from "../assets/icons/visit-report-dark.svg";
import { useTranslation } from "react-i18next";
interface NavbarProps {
  open: boolean;
  setOpen: any;
}
const Navbar: React.FC<NavbarProps> = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [navItems, setNavItems] = useState<any>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // Track the hovered item
  const navRef = useRef<HTMLDivElement>(null);
  const isActive = (route: string) => location.pathname === route;
  const [isRTL, setIsRTL] = useState(false);
  const handleSection = async (section: any) => {
    switch (section) {
      case "My Team":
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: t("Nav.home"),
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
          {
            route: URL_MAPPING.ANALYTICS,
            label: t("Nav.dashboard"),
            icon: Dashboard,
            iconDark: DashboardDark,
          },
          // {
          //   route: URL_MAPPING.ANALYTICS,
          //   label:  t("Nav.analytics"),
          //   icon: Icon11,
          //   iconDark: Icon11s,
          // },

          {
            route: URL_MAPPING.SALESMAN,
            label: t("Nav.mapView"),
            icon: MapView,
            iconDark: MapViewDark,
          },
          {
            route: URL_MAPPING.VISITREPORT,
            label: t("Nav.visitsReport"),
            icon: VisitReport,
            iconDark: VisitReportDark,
          },
          {
            route: URL_MAPPING.ACTIVITYREPORT,
            label: t("Nav.activityReport"),
            icon: EmpList,
            iconDark: EmpListDark,
          },
        ]);
        break;
      case "Customers":
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: t("Nav.home"),
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
          // {
          //   route: URL_MAPPING.AGINGREPORT,
          //   label:  t("Nav.agingReport"),
          //   icon: MapView,
          //   iconDark: MapViewDark,
          // },
          {
            route: URL_MAPPING.MASTERLIST,
            label: t("Nav.masterList"),
            icon: EmpList,
            iconDark: EmpListDark,
          },
        ]);

        break;
      case "Planogram":
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: "Home",
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
          {
            route: URL_MAPPING.PLANOGRAM,
            label: "Planogram Report",
            icon: EmpList,
            iconDark: EmpListDark,
          },
          // {
          //   route: URL_MAPPING.LANDING,
          //   label: "Master List",
          //   icon: MasterList,
          //   iconDark: MasterList,
          // },
        ]);
        break;
      case "Admin":
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: "Home",
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
        ]);
        break;
      case "Territory":
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: "Home",
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
        ]);
        break;
      default:
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: "Home",
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
        ]);
        break;
    }
  };

  const dir = document.documentElement.getAttribute("dir");
  useEffect(() => {
    const section = localStorage.getItem("section");
    if (!section) {
      navigate(URL_MAPPING.LANDING);
    }
    handleSection(section);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dir]);

  useEffect(() => {
    let isRtl = false;
    isRtl = dir === "rtl";
    setIsRTL(isRtl);
  }, [dir]);

  const handleClickOutside = (event: MouseEvent) => {
    if (navRef.current && !navRef.current.contains(event.target as Node)) {
      setOpen(false); // Close the Navbar when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={navRef}
      className={` md:flex h-screen md:h-[calc(100%-51px)] border-2 bg-[#FFFFFF] flex flex-col justify-start items-center absolute ${
        isRTL ? "right-0" : "left-0"
      } gap-0 z-30 top-0 md:top-[48px] 
      ${
        open ? "w-[251px] md:w-[220px] " : "hidden w-[56px] delay-150"
      } transition-all duration-300 `}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <ul className="flex flex-col items-center justify-start gap-1 mt-4 w-full overflow-hidden	">
        {navItems.map((item: any, index: number) => (
          <li
            key={index}
            onClick={() => navigate(item.route)}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            className={`w-full items-center h-[54px] px-2 flex ${
              item.route === URL_MAPPING.LANDING
                ? "hover:bg-[#F3E6F4] hover:text-purple"
                : ""
            } group cursor-pointer ${
              isActive(item.route) ? "bg-[#B0F7F5]" : ""
            }`}
          >
            <button className="p-2  flex gap-2 cursor-pointer items-center">
              <img
                src={
                  isActive(item.route) || hoveredIndex === index
                    ? item.iconDark
                    : item.icon
                }
                alt={item.label}
                className="max-w-[50px]"
              />
              <p
                className={`transition-opacity duration-200 delay-150 ${
                  open ? "opacity-100" : "opacity-0"
                } group-hover:opacity-100 group-hover:text-black ${
                  isActive(item.route) ? "text-black " : "text-grey"
                }`}
              >
                {item.label}
              </p>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navbar;
