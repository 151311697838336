import React from "react";
import DashboardPage from "../../layout";
import BackArrow from "../../assets/back-arrow.svg";
import TableComponent from "../../components/Table";
import Button from "../../components/Button";
import PieChart from "../../components/PieChart";

interface PieData {
  id: string;
  label: string;
  value: number;
  color: string;
}

const customer_details = () => {
  const data: PieData[] = [
    {
      id: "Sales",
      label: "Sales",
      value: 29,
      color: "hsl(246, 70%, 50%)",
    },
    {
      id: "Pending",
      label: "Pending",
      value: 71,
      color: "hsl(221, 70%, 50%)",
    },
  ];
  return (
    <DashboardPage>
      <div>
        <div className="flex border-b-2 p-3 gap-2">
          <img src={BackArrow} alt="back" />
          Back
        </div>

        <div className="flex h-[calc(100vh-102px)]">
          <div className="border-r-2 w-1/2">
            <div className="flex ">
              <div className="p-2 px-4 w-1/2 border-r-2">
                <p className="text-14 text-grey">Customer Name</p>
                <p className="text-18 text-purple">Anisur Rahman</p>
              </div>

              <div className="p-2 px-4 w-1/2 border-r-2">
                <p className="text-14 text-grey">Status</p>
                <p className="text-18 text-green">Active</p>
              </div>

              <div className="p-2 px-4 w-full ">
                <p className="text-14 text-grey">Assigned Salesman Details</p>
                <p className="text-18">Abdul Majid +971-505-556-856</p>
              </div>
            </div>
            <div className="p-2  flex gap-2 border-t-2">
              <div className=" w-full border-2">
                <div className="border-b-2 p-3">Customer Review</div>
                <div className="border-b-2 p-3 flex gap-2">
                  <Button label="Feedback" type="primary" />
                  <Button label="Complaints" />
                </div>
                <div className="overflow-auto h-[calc(100vh-310px)]">
                  <div className="border-b-2 p-3 flex flex-col gap-2">
                    <div className="flex justify-between">
                      <p className="text-purple text-14">Feedback 1</p>
                      <p className="text-10">22 Mar 2024 | 12:36 PM</p>
                    </div>
                    <p className="text-12">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                  <div className="border-b-2 p-3 flex flex-col gap-2">
                    <div className="flex justify-between">
                      <p className="text-purple text-14">Feedback 1</p>
                      <p className="text-10">22 Mar 2024 | 12:36 PM</p>
                    </div>
                    <p className="text-12">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                  <div className="border-b-2 p-3 flex flex-col gap-2">
                    <div className="flex justify-between">
                      <p className="text-purple text-14">Feedback 1</p>
                      <p className="text-10">22 Mar 2024 | 12:36 PM</p>
                    </div>
                    <p className="text-12">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" w-full flex flex-col gap-2">
                <div className="border-2">
                  <div className="p-2 border-b-2">Customer Sales</div>
                  <div className="p-2 h-[180px] flex">
                    <PieChart data={data} />
                  </div>
                </div>
                <div className="border-2">
                  <div className="p-2 border-b-2">Customer Location</div>
                  <div className="p-2">
                    <p>AAW General Trading PO Box 12345, Dubai, UAE</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <div className="flex gap-2 p-3 px-4 border-b-2">
              <Button label="Payment Terms" type="primary" />
              <Button label="Invoices" />
              <Button label="Payments" />
              <Button label="Account Statements" />
            </div>
            <div className="p-2">
              <TableComponent />
            </div>
          </div>
        </div>
      </div>
    </DashboardPage>
  );
};

export default customer_details;
