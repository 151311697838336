import React from "react";
import { ResponsivePie } from "@nivo/pie";

interface PieChartProps {
  data: any;
}

const PieChart: React.FC<PieChartProps> = ({ data }) => {
  return (
    <div className="h-[80%] w-full">
      <ResponsivePie
        data={data}
        margin={{ top: 10, right: 20, bottom: 40, left: 20 }}
        innerRadius={0.5}
        fit={true}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ datum: "data.color" }}
        // colors={{ scheme: "nivo" }}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="white"
        theme={{
          labels: {
            text: {
              fontSize: 16,
              fontWeight: 700,
              fill: "#FFFFFF",
            },
          },
        }}
        // defs={[
        //   {
        //     id: "dots",
        //     type: "patternDots",
        //     background: "inherit",
        //     color: "rgba(255, 255, 255, 0.3)",
        //     size: 4,
        //     padding: 1,
        //     stagger: true,
        //   },
        //   {
        //     id: "lines",
        //     type: "patternLines",
        //     background: "inherit",
        //     color: "rgba(255, 255, 255, 0.3)",
        //     rotation: -45,
        //     lineWidth: 6,
        //     spacing: 10,
        //   },
        // ]}
      />
      {/* <div className="flex flex-row gap-2 items-center p-2">
        {data &&
          data?.map((item: any, index: number) => {
            return (
              <div className="flex gap-2 items-center" key={index}>
                <div className={`bg-${item.color} w-[8px] h-[20px] rounded`}>
                  {" "}
                </div>
                <div> {item.label}  {item.value}</div>
              </div>
            );
          })}
      </div> */}
    </div>
  );
};

export default PieChart;
