import React, { useEffect, useState, useRef } from "react";
import DashboardWrapper from "../../layout/index";
import getLeaderBoardList, { getDashboardKpiData } from "../../api/dashobard";
import LeaderTable from "../../components/LeaderTable";
import Loader from "../../components/Loader";
import Dropdown from "../../components/Dropdown";
import {
  formatCurrentEndDate,
  formatCurrentStartDate,
  getUserInfo,
} from "../../utility";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import { formatDate } from "../../utility";
import { useLocation } from "react-router-dom";
import Filter from "../../assets/icons/Filter.svg";
import Close from "../../assets/close-circle.svg";
import Channel1060 from "./Channel1060";
import Channel50 from "./Channel50";
import Channel20 from "./Channel20";
import Channel40 from "./Channel40";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [leaderData, setLeaderData] = useState<any>([]);
  const [leaderDataList, setLeaderDataList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [startDate, setStartDate] = useState<any | undefined>(getCurrentDate());
  const [endDate, setEndDate] = useState<any | undefined>(getCurrentDate());
  const [dashobardData, setDashboardData] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataLoader, setDataLoader] = useState<boolean>(false);
  const { t } = useTranslation();
  const [salesOffice, setSelectedSalesOffice] = useState<any>();
  const [salesOfficeId, setSelectedSalesOfficeId] = useState<any>();
  const [salesRegion, setSelectedSalesRegion] = useState<any>();
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [regionList, setRegionList] = useState<any>([]);
  const location = useLocation();
  const [emp, setEmployee] = useState<any>(location.state || null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [channel, setChannel] = useState<Number>();

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const channelId = getUserInfo().channel_id;
    if (channelId) {
      setChannel(Number(channelId));
    } else {
      setChannel(20);
    }
  }, []);

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleSalesOffice = (e: any) => {
    console.log(e, "evenr");
    setSelectedSalesOffice(e);
    const office = leaderData?.filters?.sales_offices?.filter(
      (item: any) => item.name === e
    );
    // setRegionList()
    if (office) {
      setRegionList(office[0]?.regions);
      setSelectedSalesOfficeId(office[0]?.sales_office_id);
    }
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClearFilters = () => {
    // Define default values
    const defaultStartDate = getCurrentDate();
    const defaultEndDate = getCurrentDate();

    // Reset state to default values
    setSelectedSalesRegion("");
    setEmployee("");
    setRegionList([]);
    setSelectedSalesOfficeId([]);
    setSelectedSalesOffice("");
    setSelectedSalesOfficeId("");
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setSelectTimeFrame("Today");

    handleClearApply(defaultStartDate, defaultEndDate);
  };

  const handleClearApply = (startDate: string, endDate: string) => {
    setOpen(false);

    const data: any = {};
    if (startDate) {
      data["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (endDate) {
      data["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    getDashboardData(data);

    const param = {
      page: 1,
      size: 15,
    };
    setCurrentPage(1);

    const dataLeader: any = {};
    if (startDate) {
      dataLeader["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (endDate) {
      dataLeader["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    getLeaderBoardData(param, dataLeader);
  };

  const getIndex = (data: any, index: any) => {
    return Number(index + 1);
  };

  const getDashboardData = async (data: any) => {
    await getDashboardKpiData(data)
      .then((res) => {
        setDashboardData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLeaderBoardData = async (param: any, data: any) => {
    setIsLoading(true);
    await getLeaderBoardList(param, data)
      .then((res) => {
        setLeaderData(res.data);
        setIsLoading(false);
        if (currentPage === 1) {
          setLeaderDataList(res.data.items);
        } else {
          setLeaderDataList((prevData: any) => [
            ...prevData,
            ...res.data.items,
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const data: any = {};
    if (startDate) {
      data["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      data["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOfficeId) {
      data["sales_office_ids"] = [salesOfficeId];
    }
    if (salesRegion) {
      data["regions"] = [salesRegion];
    }
    if (emp) {
      data["subordinate_ids"] = [emp];
    }

    getDashboardData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApply = () => {
    setOpen(false);
    const data: any = {};
    if (startDate) {
      data["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      data["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOfficeId) {
      data["sales_office_ids"] = [salesOfficeId];
    }
    if (salesRegion) {
      data["regions"] = [salesRegion];
    }
    if (emp) {
      data["subordinate_ids"] = [emp];
    }
    getDashboardData(data);

    const param = {
      page: 1,
      size: 15,
    };

    setCurrentPage(1);
    const dataLeader: any = {};

    if (startDate) {
      dataLeader["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      dataLeader["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOfficeId) {
      dataLeader["sales_office_ids"] = [salesOfficeId];
    }
    if (salesRegion) {
      dataLeader["regions"] = [salesRegion];
    }
    if (emp) {
      dataLeader["subordinate_ids"] = [emp];
    }

    getLeaderBoardData(param, dataLeader);
  };

  useEffect(() => {
    const param = {
      page: currentPage,
      size: 15,
    };

    const data: any = {};

    if (startDate) {
      data["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      data["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOfficeId) {
      data["sales_office_ids"] = [salesOfficeId];
    }
    if (salesRegion) {
      data["regions"] = [salesRegion];
    }
    if (emp) {
      data["subordinate_ids"] = [emp];
    }

    getLeaderBoardData(param, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month Till Date");
    } else {
      setSelectTimeFrame("");
    }
  };

  // const handleScroll = () => {
  //   if (leaderData?.total === leaderDataList?.length) {
  //     return;
  //   }
  //   const scrollContainer = scrollContainerRef.current;
  //   if (scrollContainer) {
  //     const { scrollTop, clientHeight, scrollHeight } = scrollContainer;
  //     if (scrollTop + clientHeight >= scrollHeight - 1 && !isLoading) {
  //       setCurrentPage((prevPage) => prevPage + 1);
  //     }
  //   }
  // };

  return (
    <DashboardWrapper className="fixed-dashboard-wrapper">
      <div className="bg-white"></div>

      <div className="flex gap-3 pb-0 md:pb-0 p-4 md:p-3 w-full lg:flex-row flex-col">
        {dataLoader && (
          <div className="absolute top-[50%] left-[50%] z-50">
            <Loader />
          </div>
        )}
        <div className="lg:w-[63%] w-full">
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-1 pl-1 pb-3 text-13 font-medium">
              <p>{t("DashboardAnalytics.reportTitle")}</p>
              <p className="text-[20px] font-semibold">
                {timeFrame ? (
                  <>
                    {timeFrame === "Today" && t("DashboardAnalytics.today")}
                    {timeFrame === "Yesterday" && t("timeframeselector.yesterday")}
                    {timeFrame === "Month till date" &&
                      t("DashboardAnalytics.monthtilldate")}
                  </>
                ) : (
                  startDate?.split("-").reverse().join("-") +
                  " To " +
                  endDate?.split("-").reverse().join("-")
                )}
              </p>
            </div>
            <div>
              <button
                className="flex items-center px-4 p-3 gap-2 bg-[#FAFAFA] border-2 border-[#EBEBEB]"
                onClick={() => setOpen(true)}
              >
                <img src={Filter} alt="" />{" "}
                {t("DashboardAnalytics.filterButton")}
              </button>
            </div>
          </div>

          {channel === 20 && <Channel20 dashobardData={dashobardData} />}
          {(channel === 10 || channel === 60) && (
            <Channel1060 dashobardData={dashobardData} />
          )}
          {channel === 50 && <Channel50 dashobardData={dashobardData} />}
          {channel === 40 && <Channel40 dashobardData={dashobardData} />}
        </div>

        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
            <div className="bg-white shadow-lg w-full max-w-md h-full">
              <div className="flex justify-between p-6 border-b-2">
                <div className="text-16 font-bold">
                  {t("DashboardAnalytics.filtersTitle")}
                </div>
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                    src={Close}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between h-[calc(100vh-100px)] px-12 pt-6">
                <div className="flex flex-col gap-[30px]">
                  <Dropdown
                    label={t("DashboardAnalytics.employeeNameLabel")}
                    options={
                      leaderData?.filters?.employees?.map((employee: any) => ({
                        value: employee.emp_id,
                        label: employee.name_eng,
                      })) || []
                    }
                    selectedValue={emp}
                    searchable={true}
                    onChange={(value) => setEmployee(value)}
                    placeholder={t(
                      "DashboardAnalytics.employeeNamePlaceholder"
                    )}
                  />

                  <div className="flex flex-col sm:flex-row gap-4 justify between sm:space-y-0 sm:space-x-4">
                    <Dropdown
                      label={t("DashboardAnalytics.salesOfficeLabel")}
                      options={
                        leaderData?.filters?.sales_offices?.map(
                          (office: any) => ({
                            value: office.name,
                            label: office.name,
                          })
                        ) || []
                      }
                      selectedValue={salesOffice}
                      onChange={handleSalesOffice}
                      placeholder={t(
                        "DashboardAnalytics.salesOfficePlaceholder"
                      )}
                    />

                    <Dropdown
                      label={t("DashboardAnalytics.regionLabel")}
                      options={
                        regionList?.map((region: any) => ({
                          value: region,
                          label: region,
                        })) || []
                      }
                      selectedValue={salesRegion}
                      onChange={(value) => setSelectedSalesRegion(value)}
                      placeholder={t("DashboardAnalytics.regionPlaceholder")}
                    />
                  </div>

                  <div className="date-range-picker">
                    <div className="flex flex-col sm:flex-row gap-4  sm:space-y-0 sm:space-x-4">
                      <div className="w-full">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("DashboardAnalytics.startDateLabel")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={startDate}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={handleStartDateChange}
                        />
                      </div>
                      <div className="w-full">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("DashboardAnalytics.endDateLabel")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={endDate}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={handleEndDateChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <TimeFrameSelector
                      customClass="w-full border border-gray-300 rounded p-2"
                      setSelectTimeFrame={setSelectTimeFrame}
                      timeFrame={timeFrame}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>

                <div className="flex justify-between mt-4">
                  <button
                    onClick={handleClearFilters}
                    className="px-4 py-2 text-black bg-gray-200 rounded transition duration-200 hover:bg-gray-300"
                  >
                    {t("DashboardAnalytics.clearFilterButton")}
                  </button>
                  <button
                    onClick={handleApply}
                    className="px-4 py-2 text-white bg-purple rounded transition duration-200 hover:bg-[#F3E6F4] hover:text-purple"
                  >
                    {t("DashboardAnalytics.applyFilterButton")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="lg:max-w-[35%] w-full rounded">
          <h2 className="text-base font-bold p-4 text-white bg-purple">
            {t("DashboardAnalytics.leaderboardTitle")}
          </h2>
          <div
            ref={scrollContainerRef}
            id="scorll-container"
            className="overflow-x-hidden overflow-scroll h-[645px] md:h-[calc(100vh-130px)] scrollbar scrollbar-thin"
          >
            <LeaderTable
              leaderData={leaderDataList}
              loader={isLoading}
              getIndex={getIndex}
            />
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default Dashboard;
