import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRTL } from "../utility";
import DownArrow from "../assets/down-arrow.svg";
import UpArrow from "../assets/up-arrow.svg";

const LanguageSelector: React.FC = () => {
  const [selectedLang, setSelectedLang] = useState<string>("English");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { i18n } = useTranslation();

  // Apply RTL direction based on language
  useRTL(selectedLang === "Arabic");

  useEffect(() => {
    const lang = localStorage.getItem("lang") || "English";
    setSelectedLang(lang);
    i18n.changeLanguage(lang === "Arabic" ? "ar" : "en");
  }, [i18n]);

  const handleLanguageChange = (lang: string) => {
    setSelectedLang(lang);
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang === "Arabic" ? "ar" : "en");
    setIsDropdownOpen(false);
    window.location.reload()
  };

  return (
    <div className="relative flex items-center border-2 rounded-3xl p-0.5 px-1 bg-white">
      {/* Language Display */}
      <span className="text-lg font-bold bg-cyan py-0.5 px-6 rounded-3xl">
        {selectedLang?.charAt(0)}
      </span>

      {/* Dropdown Trigger */}
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="text-13 font-bold flex items-center p-1 outline-none focus:outline-none focus:ring-0 mx-2"
      >
        <span>{selectedLang}</span>
        {/* Downward Arrow */}
        <span className="mx-2 text-sm">
          <img src={isDropdownOpen ? UpArrow : DownArrow} alt="" />
        </span>
      </button>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute top-full right-0 mt-2 w-40 bg-white border border-gray-300 rounded-lg shadow-lg z-30">
          <ul className="py-1">
            <li>
              <button
                onClick={() => handleLanguageChange("English")}
                className={`block w-full text-left px-4 py-2 text-13  hover:bg-gray-100 `}
              >
                English
              </button>
            </li>
            <li>
              <button
                onClick={() => handleLanguageChange("Arabic")}
                className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100`}
              >
                Arabic
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
