import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import NewPassword from "./newPassword";
import URL_MAPPING from "../../routes/constants";
import { resetPass } from "../../api/login";
import ImageSection from "./imageSection";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPass: React.FC = () => {
  const [error, setError] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const handleNewPassword = async (pass: any) => {
    if (
      pass.length > 0 &&
      !pass.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/)
    ) {
      return setError(
        "Password must contain 8 characters with an uppercase letter, a lowercase letter, a special character and a number."
      );
    } else {
      setError("");
    }
    setError("");
    setLoader(true);
    await resetPass(token, pass)
      .then((res) => {
        if (res.data.mfa_enabled === true) {
          toast.success("Success");
          toast.success("Redirecting to the login page!");
          setTimeout(() => {
            navigate(URL_MAPPING.LOGIN);
          }, 2000);
        } else {
          navigate(URL_MAPPING.GOOGLEAUTH, {
            state: { token: res?.data?.token },
          });
        }
        setLoader(false);
      })
      .catch((error) => {
        setError(error.response?.data?.detail?.message || "Invalid session");
        setLoader(false);
      });
  };

  return (
    <>
      <div className="flex h-screen flex-col md:flex-row">
        <ToastContainer />
        <div className="md:w-1/2 flex-col relative w-full h-[120px] md:h-full">
          <ImageSection />
        </div>
        <>
          <NewPassword
            handleSubmit={handleNewPassword}
            loader={loader}
            checkError={error}
          />
        </>
      </div>
    </>
  );
};

export default ResetPass;
