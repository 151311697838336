import axios from "./config";
import { getHeader, getUserInfo } from "../utility/index";


const getEmployeeList = async (params: any, data: any): Promise<any> => {
  const user_info = getUserInfo();

  const response = await axios.post<any>(
    `/reports/${user_info.emp_id}/visit-activity-report`,
    data,
    {
      headers: getHeader(),
      params: params,
    }
  );
  return response.data;
};

const visitReportList = async (params: any, data: any): Promise<any> => {
  const user_info = getUserInfo();

  const response = await axios.post<any>(
    `/customer/${user_info.emp_id}/visit-report`,
    data,
    {
      headers: getHeader(),
      params: params,
    }
  );
  return response.data;
};


const visitSummryReportList = async (params: any, data: any): Promise<any> => {
  const user_info = getUserInfo();

  const response = await axios.post<any>(
    `/reports/${user_info.emp_id}/visit-summary-report`,
    data,
    {
      headers: getHeader(),
      params: params,
    }
  );
  return response.data;
};


const getAllCustomerList = async (): Promise<any> => {
  const user_info = getUserInfo();

  const response = await axios.get<any>(
    `/reports/${user_info.emp_id}/all-customers`,
    {
      headers: getHeader(),
    }
  );
  return response.data;
};

export { visitReportList, visitSummryReportList ,getAllCustomerList };
export default getEmployeeList;
