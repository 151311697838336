import React from "react";

// Props for the HorizontalBar component
interface HorizontalBarProps {
  invoiceValue: number | string; // Value for Invoice
  poValue: number | string; // Value for PO
  maxValue?: number; // Maximum value for scaling
  firstName?: string;
  secondName?: string;
}

// HorizontalBar component to display the two sections
const HorizontalBar: React.FC<HorizontalBarProps> = ({
  invoiceValue,
  poValue,
  maxValue,
  firstName,
  secondName,
}) => {
  // Calculate the total
  const total = Number(invoiceValue) + Number(poValue);

  // Calculate percentage for each value
  const invoicePercentage = (Number(invoiceValue) / total) * 100;
  const poPercentage = (Number(poValue) / total) * 100;

  return (
    <div className="flex flex-col ">
      <div className="flex-1 h-10 bg-gray-200 rounded flex">
        {" "}
        {/* Increased height */}
        <div
          className="h-[25px] bg-cugreen rounded-l" // Changed to green for Invoice
          style={{ width: `${invoicePercentage}%` }}
        />
        <div
          className="h-[25px] bg-blue-500 rounded-r" // Changed to blue for PO
          style={{ width: `${poPercentage}%` }}
        />
      </div>
      <div>
        <ul className="mt-6 flex gap-8  flex-wrap ">
          <li className="flex  items-center gap-2 pl-2">
            <div className="h-12 w-[6px] rounded-lg bg-cugreen"></div>
            <div className="flex-col flex">
              <span>{firstName}</span>
              <span className="font-bold">{invoiceValue}</span>
            </div>
          </li>
          <li className="flex gap-2 items-center  ">
            <div className="h-12 w-[6px] rounded-lg bg-[#408BFC] "></div>
            <div className="flex-col flex">
              <span>{secondName}</span>
              <span className="font-bold">{poValue}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HorizontalBar;
