import React, { ReactNode,useEffect, useRef, useState } from "react";
import { useNavigate,useLocation} from "react-router-dom";
import Popup from "../components/AuthPopup"; 
import Cookies from "js-cookie";
import { logout } from "../api/login";
import URL_MAPPING from "../routes/constants";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../notification";

interface AutoLogoutProps {
    children: ReactNode; 
  }
  const AutoLogout: React.FC<AutoLogoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();
  const {DisconnectingSseEvent} = useNotifications();
  const handleLogout = () => {
    logout()
    Cookies.remove("token");
    Cookies.remove("refresh_token");
    localStorage.clear();
    DisconnectingSseEvent();
    navigate(URL_MAPPING.LOGIN);
  };

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
        if (Cookies.get("token")) {  
        handleLogout(); 
        setShowPopup(true); 
        }
      }, 15*60*1000); 
    };
  

  
  const handleUserActivity = () => {
    if (showPopup)  return; 
    resetTimer();
  };

  useEffect(() => {
    resetTimer();

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
    };
  }, [showPopup]);
  const handleOkButtonClick = () => {
   
    setShowPopup(false); 
  };
  return (
    <>
      {children}
      <Popup
        isOpen={showPopup}
        title={t("popup.sessiontimeout")}
        setShowPopup={setShowPopup}
      >
        <p>{t("popup.sessionexpirymessage")}</p>
        <div onClick={handleOkButtonClick} />
      </Popup>
    </>
  );
};

export default AutoLogout;