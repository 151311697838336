import React, { useState, useEffect } from "react";
import DashboardPage from "../../layout/index";
import approveRequest, { getAllNotification } from "../../api/header";
import { useNotifications } from "../../notification";
import GoogleMap from "../../components/GoogleMap";
import moment from "moment";
import BackArrow from "../../assets/back-arrow.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatCurrentEndDate, formatCurrentStartDate } from "../../utility";
import { getMarkerInfo } from "../../api/mapView";
import Dropdown from "../../components/Dropdown";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

const Notification: React.FC = () => {
  const {
    notifications,
    approveNotification,
    setNotif,
    setCount,
    setHasNewNotifications,
  } = useNotifications();
  const [locationInfo, setLocationInfo] = useState<any>(null);
  const navigate = useNavigate();
  const [markarData, setMarkarData] = useState<any | null>(null);
  const [allNotification, setAllNotification] = useState<any[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<any | null>(null);
  const [uniqueNotifications, setUniqueNotifications] = useState<any[]>([]);
  const [fillterData, setFillterdData] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [loader, setLoader] = useState<any>(false);

  useEffect(() => {
    setNotif(false);
    setCount(0);
    setHasNewNotifications(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApprove = async (data: any) => {
    setLocationInfo(null);
    setHasNewNotifications(false);
    setCount(0);
    setNotif(false);
    await approveRequest(data?.id, {
      action: "APPROVE",
    })
      .then((res) => {
        fileerNotificaiton(data);
        toast.success(`Approved for ${data.name_eng}`);
      })
      .catch((error) => {
        console.log(error);
        fileerNotificaiton(data);
        toast.error("Request is expired");
      });
  };
  const handleReject = async (data: any) => {
    setHasNewNotifications(false);
    setNotif(false);
    setCount(0);
    setLocationInfo(null);
    await approveRequest(data?.id, {
      action: "REJECT",
    })
      .then((res) => {
        fileerNotificaiton(data);
        toast.success(`Rejected for ${data.name_eng}`);
      })
      .catch((error) => {
        console.log(error);
        fileerNotificaiton(data);
        toast.error("Request is expired");
      });
  };

  const fileerNotificaiton = (data: any) => {
    approveNotification(data?.id);
    getNotification();
    setUniqueNotifications((prev) =>
      prev.filter((notification) => notification?.id !== data.id)
    );
  };
  const getLocationData = (locationInfo: any) => {
    const transformedData = {
      position: {
        lat: parseFloat(locationInfo?.employee_latitude),
        lng: parseFloat(locationInfo?.employee_longitude),
      },
      id: locationInfo?.emp_id,
    };
    return transformedData;
  };

  const getCustomerLocation = (locationInfo: any) => {
    const transformedData = {
      position: {
        lat: parseFloat(locationInfo?.customer_latitude),
        lng: parseFloat(locationInfo?.customer_longitude),
      },
      id: locationInfo?.customer_id,
    };
    return transformedData;
  };

  const getMarkerInfoData = async (emp_id: any) => {
    const params = {
      start_date: formatCurrentStartDate(new Date()),
      end_date: formatCurrentEndDate(new Date()),
    };
    await getMarkerInfo(emp_id, params)
      .then((res) => {
        setMarkarData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNotification = async () => {
    await getAllNotification()
      .then((res) => {
        setAllNotification(res?.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    setLoader(true);
    getNotification();
  }, []);

  const handleMarkerClick = (marker: any) => {
    setMarkarData(null);
    setSelectedMarker(marker);
    getMarkerInfoData(marker?.id);
  };

  const handleCloseInfoWindow = () => {
    setSelectedMarker(null);
  };

  useEffect(() => {
    if (selectedStatus !== "") {
      setFillterdData( uniqueNotifications.filter((item) => item.status === selectedStatus) );
    } else {
      setFillterdData(uniqueNotifications);
    }
  }, [selectedStatus, uniqueNotifications, allNotification]);

  const setNewNotificaiton = (data: any) => {
    if (selectedStatus !== "") {
      setFillterdData(
        data.filter((item: any) => item.status === selectedStatus)
      );
    } else {
      setFillterdData(data);
    }
  };
  const handleNotificaitocClick = (notif: any) => {
    if (notif?.id === locationInfo?.id) {
      setLocationInfo(null);
    } else {
      setLocationInfo(notif);
    }
  };
  const { t } = useTranslation();
  useEffect(() => {
    const mergedNotifications = [...allNotification, ...notifications];

    const unique = mergedNotifications.filter(
      (notification, index, self) =>
        index === self.findIndex((n) => n.id === notification.id) // Adjust `id` to the unique key of your notification object
    );
    setUniqueNotifications(unique);
    setNewNotificaiton(unique);
  }, [notifications, allNotification]);

  return (
    <DashboardPage>
  <ToastContainer />
  <div className="flex flex-col md:flex-row">
    <div className="w-full md:w-[35%] flex flex-col border-r-2 mr-4 space-x-4">
      <div className="flex items-center gap-3 p-4 justify-between">
        <div
          className="flex gap-1 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img src={BackArrow} alt="" />
          {t("notifications.back")}
        </div>
        <div className="w-[40%]">
          <Dropdown
            label={t("notifications.filterByStatus")}
            options={[
              { value: "", label: t("notifications.all") },
              { value: "APPROVED", label: t("notifications.approved") },
              { value: "REJECTED", label: t("notifications.rejected") },
              { value: "EXPIRED", label: t("notifications.expired") },
            ]}
            selectedValue={selectedStatus}
            onChange={setSelectedStatus}
            placeholder={t("notifications.select")}
          />
        </div>
      </div>

      <div className="md:h-[calc(100vh-120px)] h-[500px] scrollbar scrollbar-thin overflow-x-hidden overflow-y-auto">
        {!loader && fillterData.length > 0 ? (
          fillterData
            ?.slice()
            .reverse()
            .map((notification: any) => {
              return (
                <div
                  key={notification?.id}
                  className={`${
                    notification.status === "NEW" && "bg-lightgrey"
                  } px-4 py-4 pl-4 text-gray-700 hover:bg-gray-100 border-t-2 flex flex-col gap-2 ${
                    notification?.id === locationInfo?.id ? "bg-cyan" : ""
                  }`}
                >
                  <div
                    className="flex justify-between cursor-pointer"
                    onClick={(e) => handleNotificaitocClick(notification)}
                  >
                    <div className="flex">
                      {t("notifications.notificationNo")}: {notification.id}
                    </div>
                    <div className="flex">
                      {moment(notification?.created_at).format(
                        "D MMM | hh.mA"
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between items-center gap-1">
                    {notification.operation_type === "CHECK_IN" && (
                      <>
                        <div
                          className="cursor-pointer"
                          onClick={(e) =>
                            handleNotificaitocClick(notification)
                          }
                        >
                          <span className="uppercase font-bold">
                            {notification.name_eng}
                          </span>{" "}
                          {t("notifications.hasSubmitted")}{" "}
                          <span className="font-bold uppercase">
                            {t("notifications.checkInRequest")}
                          </span>
                          . {t("notifications.userAwayFromCustomer")}
                          <br />
                          {notification.description && (
                            <>
                              <span className="italic font-semibold">
                                {t("notifications.comment")}
                              </span>
                              : {notification.description}{" "}
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {notification.operation_type === "CHECK_OUT" && (
                      <>
                        <div
                          className="cursor-pointer"
                          onClick={(e) =>
                            handleNotificaitocClick(notification)
                          }
                        >
                          <span className="uppercase font-bold">
                            {notification.name_eng}
                          </span>{" "}
                          {t("notifications.hasSubmitted")}{" "}
                          <span className="font-bold uppercase">
                            {t("notifications.checkOutRequest")}
                          </span>
                          . {t("notifications.userAwayFromCustomer")}
                          <br />
                          {notification.description && (
                            <>
                              <span className="italic font-semibold">
                                {t("comment")}
                              </span>
                              : {notification.description}{" "}
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {notification.operation_type === "UNSCHEDULED" && (
                      <>
                        <div
                          className="cursor-pointer"
                          onClick={(e) =>
                            handleNotificaitocClick(notification)
                          }
                        >
                          <span className="uppercase font-bold">
                            {notification.name_eng}
                          </span>{" "}
                          {t("notifications.hasSubmitted")}{" "}
                          <span className="font-bold uppercase">
                            {t("notifications.unscheduledVisitRequest")}
                          </span>
                          <br />
                          {notification.description && (
                            <>
                              <span className="italic font-semibold">
                                {t("notifications.comment")}
                              </span>
                              : {notification.description}{" "}
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {notification.operation_type === "UNSCHEDULED_50MTR" && (
                      <>
                        <div
                          className="cursor-pointer"
                          onClick={(e) =>
                            handleNotificaitocClick(notification)
                          }
                        >
                          <span className="uppercase font-bold">
                            {notification.name_eng}
                          </span>{" "}
                          {t("notifications.hasSubmitted")}{" "}
                          <span className="font-bold uppercase">
                            {t("notifications.unscheduledVisitAway")}
                          </span>
                          <br />
                          {notification.description && (
                            <>
                              <span className="italic font-semibold">
                                {t("notifications.comment")}
                              </span>
                              : {notification.description}{" "}
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {notification.operation_type === "UPDATE_COORDINATES" && (
                      <>
                        <div
                          className="cursor-pointer"
                          onClick={(e) =>
                            handleNotificaitocClick(notification)
                          }
                        >
                          <span className="uppercase font-bold">
                            {notification.name_eng}
                          </span>{" "}
                          {t("notifications.hasSubmitted")}{" "}
                          <span className="font-bold uppercase">
                            {t("notifications.updateLocationRequest")}
                          </span>
                          <br />
                          {notification.description && (
                            <>
                              <span className="italic font-semibold">
                                {t("notifications.comment")}
                              </span>
                              : {notification.description}{" "}
                            </>
                          )}
                        </div>
                      </>
                    )}
                    <div className="flex justify-between flex-col h-full gap-6">
                      {notification.status !== "NEW" ? (
                        ""
                      ) : (
                        <>
                          <div className="flex justify-between gap-2 min-h-8 h-auto flex-col lg:flex-row">
                            <button
                              onClick={() => handleApprove(notification)}
                              className="bg-[#E8FAF5] py-1 rounded px-6 text-[#00C48C] border-[#00C48C] border-2 hover:bg-[#00C48C] hover:text-white"
                            >
                              {t("notifications.accept")}
                            </button>
                            <button
                              onClick={() => handleReject(notification)}
                              className="bg-[#FCEFF0] py-1 rounded px-6 text-[#FF4D68] border-[#FF4D68] border-2 hover:bg-[#FF4D68] hover:text-white"
                            >
                              {t("notifications.reject")}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
        ) : (
          <>
            {loader ? (
              <Loader />
            ) : (
              <div className="px-4 py-2 text-gray-700 flex items-center justify-center w-full">
                {t("notifications.noNewNotifications")}
              </div>
            )}
          </>
        )}
      </div>
    </div>
    <div className="w-full md:w-[65%] h-[300px] md:h-[calc(100vh-54px)] lg:h-[calc(100vh-54px)]">
      <GoogleMap
        showNotificationInfo={true}
        customerLoaction={getLocationData(locationInfo)}
        storeLoaction={getCustomerLocation(locationInfo)}
        handleMarkerClick={handleMarkerClick}
        markarData={markarData}
        selectedMarker={selectedMarker}
        handleCloseInfoWindow={handleCloseInfoWindow}
      />
    </div>
  </div>
</DashboardPage>

  );
};
export default Notification;
