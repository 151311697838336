import React from "react";
import { formatNumber } from "../utility";

interface HorizontalBarProps {
  ThirdValue: number | string;
  firstValue: number | string;
  secondValue: number | string;
  firstName?: string;
  secondName?: string;
  thirdName?: string;
}

const HorizontalBar: React.FC<HorizontalBarProps> = ({
  firstValue,
  secondValue,
  ThirdValue,
  firstName,
  secondName,
  thirdName,
}) => {
  // Calculate the total
  const total = Number(firstValue) + Number(secondValue) + Number(ThirdValue);

  // Calculate percentage for each value
  const first = (Number(firstValue) / total) * 100;
  const second = (Number(secondValue) / total) * 100;
  const third = (Number(ThirdValue) / total) * 100;

  return (
    <div className="flex flex-col ">
      <div className="flex-1 h-10 bg-gray-200 rounded flex">
        {" "}
        {/* Increased height */}
        <div
          className="h-[25px] bg-cugreen rounded-l" // Changed to green for Invoice
          style={{ width: `${first}%` }}
        />
        <div
          className="h-[25px] bg-blue-500" // Changed to blue for PO
          style={{ width: `${second}%` }}
        />
        <div
          className="h-[25px] bg-[#FF4D68]  rounded-r"
          style={{ width: `${third}%` }}
        />
      </div>
      <div>
        <ul className="mt-6 flex gap-3  flex-wrap ">
          <li className="flex  items-center gap-2 justify-between w-full">
            <div className="flex items-center gap-2">
              <div className="h-[10px] w-[10px] rounded-lg bg-cugreen"></div>
              <span>{firstName}</span>
            </div>
            <span className="font-bold">{(firstValue)}</span>
          </li>
          <li className="flex gap-2 items-center justify-between w-full">
            <div className="flex items-center gap-2">
              <div className="h-[10px] w-[10px] rounded-lg bg-[#408BFC] "></div>
              <span>{secondName}</span>
            </div>
            <span className="font-bold">{(secondValue)}</span>
          </li>
          <li className="flex gap-2 items-center justify-between w-full ">
            <div className="flex items-center gap-2">
              <div className="h-[10px] w-[10px] rounded-lg bg-[#FF4D68] "></div>
              <span>{thirdName}</span>
            </div>
            <span className="font-bold">{(ThirdValue)}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HorizontalBar;
