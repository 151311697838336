import axios from "./config";
import { getHeader, getUserInfo } from "../utility/index";

interface Customer {}

interface ApiResponse {
  data: Customer[];
}

const getCustomerList = async (
  params: any,
  data: any
): Promise<ApiResponse> => {
  const user_info = getUserInfo();

  const response = await axios.post<ApiResponse>(
    `/customer/${user_info.emp_id}/customer-aging-report`,
    data,
    {
      headers: getHeader(),
      params: params,
    }
  );
  return response.data;
};

export default getCustomerList;
