import axios from "./config";
import { getHeader, getUserInfo } from "../utility/index";

const getCustomerList = async (params: any, data: any): Promise<any> => {
  const user_info = getUserInfo();

  const response = await axios.post<any>(
    `/reports/${user_info.emp_id}/customer-master-report`,
    data,
    {
      headers: getHeader(),
      params: params,
    }
  );
  return response.data;
};

export default getCustomerList;
