import React, { useEffect, useState } from "react";
import DashboardPage from "../../layout";
import getEmployeeList, { getAllCustomerList } from "../../api/employee";
import PaginationComponent from "../../components/Pagination";
import Loader from "../../components/Loader";
import Export from "../../assets/icons/Export.svg";
import Filter from "../../assets/icons/Filter.svg";
import { formatCurrentStartDate, formatCurrentEndDate } from "../../utility";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import { formatDate } from "../../utility";
import Dropdown from "../../components/Dropdown";
import { useTranslation } from "react-i18next";
import Close from "../../assets/close-circle.svg";
import Search from "../../assets/Search.svg";
import Eye from "../../assets/eye.svg";
import moment from "moment";
import { formatNumber } from "../../utility";
import Button from "../../components/Button";
import ImageDailog from "../../components/ImageDailog";

const EmployeeTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const employeesPerPage = 15;
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [employees, setEmployeesData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<any>("");
  const [selectedSalesOffice, setSelectedSalesOffice] = useState<any>("");
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [channel, setChannel] = useState<any>("");
  const [usertype, setUsertype] = useState<any>("");
  const [route, setRoute] = useState<any>("");
  const [territory, setTerritory] = useState<any>("");
  const [empId, setEmpId] = useState<any>("");
  const { t } = useTranslation();
  const [sortType, setSortType] = useState<any>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [regionList, setRegionList] = useState<any>([]);
  const [allCustomer, setCustomerAllList] = useState<any>([]);
  const [customerId, setCustomerID] = useState<any>();
  const [viewImg, setViewImg] = useState<any>(false);
  const [imgUrl, setImgUrl] = useState<any>();
  const [imgType, setImgType] = useState<any>();

  useEffect(() => {
    if (selectedSalesOffice) {
      // eslint-disable-next-line array-callback-return
      employees?.filters?.sales_offices?.map((item: any) => {
        if (selectedSalesOffice === item.sales_office_id) {
          setRegionList(item.regions);
        }
      });
    } else {
      setRegionList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSalesOffice]);

  const handleFilterClick = () => {
    setOpen(true);
  };
  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const handleClearFilters = () => {
    setCustomerID(null);
    setEmpId(null);
    setSelectedSalesOffice(null);
    setSelectedRegion(null);
    setChannel(null);
    setUsertype(null);
    setRoute(null);
    setTerritory(null);
    setStartDate("");
    setEndDate("");
    setSelectTimeFrame("Today");
    setOpen(false);
    handleClearApply("");
  };
  const handleClearApply = (sort: string) => {
    const data: {
      start_date: string;
      end_date: string;
    } = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    const params = {
      page: currentPage,
    };

    getEmployeesData(data, params);
    handleClose();
  };
  const handleSearch = (e: any) => {
    setSearchQuery(e);
    const data = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      search: e,
    };
    const params = {
      page: currentPage,
    };
    getEmployeesData(data, params);
  };

  const handleSortFillter = (data: string) => {
    setSortType(data);
    setIsDropdownOpen(false);
    handleApply(data);
  };
  const getAllCustomer = async () => {
    await getAllCustomerList()
      .then((res) => {
        setCustomerAllList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => setOpen(false);

  const handleExport = async () => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      export: true,
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }

    if (channel) {
      data["channel"] = channel;
    }
    if (empId) {
      data["subordinate_ids"] = [empId];
    }
    if (customerId) {
      data["customer_id"] = [customerId];
    }

    if (usertype) {
      data["usertype"] = usertype;
    }
    if (route) {
      data["route"] = route;
    }
    if (territory) {
      data["route"] = territory;
    }

    if (sortType) {
      data["sort_by"] = sortType;
    }

    if (selectedRegion) {
      data["region"] = [selectedRegion];
    }

    if (selectedSalesOffice) {
      data["sales_office_id"] = selectedSalesOffice;
    }

    const params = {};
    exportActiviyData(data, params);
  };

  const exportActiviyData = async (data: any, params: any) => {
    await getEmployeeList(params, data)
      .then((res) => {
        console.log(res, "response");
        const url = window.URL.createObjectURL(new Blob([res]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `team_activity_report.csv`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
      });
  };
  const getEmployeesData = async (data: any, params: any) => {
    setLoading(true);
    await getEmployeeList(params, data)
      .then((res) => {
        console.log(res, "response");

        setEmployeesData(res);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
        setLoading(false);
      });
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  useEffect(() => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (selectedRegion) {
      data["regions"] = selectedRegion;
    }

    if (selectedSalesOffice) {
      data["sales_office_id"] = selectedSalesOffice;
    }

    if (channel) {
      data["channel"] = channel;
    }
    if (empId) {
      data["subordinate_ids"] = [empId];
    }
    if (customerId) {
      data["customer_id"] = [customerId];
    }
    if (sortType) {
      data["sort_by"] = sortType;
    }

    const params = {
      page: currentPage,
      size: employeesPerPage,
    };
    getEmployeesData(data, params);
    getAllCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleApply = (sort: string) => {
    const data: {
      start_date: string;
      end_date: string;
      channel_ids?: Array<any>;
      regions?: Array<any>;
      sales_office_ids?: Array<any>;
      route_ids?: Array<any>;
      territory_ids?: Array<any>;
      user_type?: Array<any>;
      sort_by?: string;
      subordinate_ids?: any;
      customer_id?: any;
    } = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (channel) {
      data["channel_ids"] = Array.isArray(channel) ? channel : [channel];
    }

    if (selectedSalesOffice) {
      data["sales_office_ids"] = [selectedSalesOffice];
    }

    if (sort) {
      data["sort_by"] = sort;
    }

    if (empId) {
      data["subordinate_ids"] = [empId];
    }
    if (customerId) {
      data["customer_id"] = [customerId];
    }
    if (selectedRegion) {
      data["regions"] = Array.isArray(selectedRegion)
        ? [selectedRegion]
        : [selectedRegion];
    }

    if (route) {
      data["route_ids"] = Array.isArray(route) ? route : [route];
    }

    if (territory) {
      data["territory_ids"] = Array.isArray(territory)
        ? territory
        : [territory];
    }

    if (usertype) {
      data["user_type"] = Array.isArray(usertype) ? usertype : [usertype];
    }

    const params = {
      page: currentPage,
    };

    getEmployeesData(data, params);
    handleClose();
  };

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month till date");
    } else {
      setSelectTimeFrame("");
    }
  };


  const handleSetImage =(url:any,type:string)=>{
    setImgUrl(url)
    setViewImg(true)
    setImgType(type)
  }
  return (
    <DashboardPage>
      <div className="bg-gray-50  sm:pl-0 pr-4 ">
        <div className="hidden mb-2 sm:flex flex-col sm:flex-row justify-between items-center bg-white p-2 border-b-2">
          <div className="w-full sm:w-auto mb-4 sm:mb-0 pl-6">
            <PaginationComponent
              totalCount={employees?.total}
              itemsPerPage={employees?.size}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="relative w-full sm:w-auto">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={Search} alt={t("planogram.searchPlaceholder")} />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder={t("planogram.searchPlaceholder")}
                className="w-full sm:w-auto pl-10 pr-4 py-2 text-sm border rounded text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
            </div>
            <div className="hidden sm:flex space-x-4 items-center relative ">
              {/* <button
                onClick={handleSort}
                className="flex items-center px-4 py-2 text-sm text-gray-600 rounded transition-all"
              >
                <img src={Sort} alt="Sort Icon" className="w-4 h-4 mr-2" />
                Sort by
              </button> */}
              {isDropdownOpen && (
                <ul className="absolute z-50 right-30 top-10 w-48 bg-white border border-gray-300 rounded shadow-lg">
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                      sortType === "ASC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("ASC")}
                  >
                    {t("planogram.ascending")}
                  </li>
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer  ${
                      sortType === "DESC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("DESC")}
                  >
                    {t("planogram.descending")}
                  </li>
                </ul>
              )}
              <button
                onClick={handleFilterClick}
                className="flex items-center gap-2 px-4 py-2 text-sm text-gray-600 rounded transition-all"
              >
                <img src={Filter} alt="Filter Icon" className="w-4 h-4 mr-2" />
                {t("planogram.filter")}
              </button>

              <button
                onClick={handleExport}
                className="flex items-center gap-2 px-4 py-2 text-sm text-gray-600 rounded border transition-all"
              >
                {t("planogram.export")}
                <img src={Export} alt="Export Icon" className="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex justify-between items-center p-2 border-b-2 bg-white">
          <div className="flex-grow">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search"
              className="w-full px-4 py-2 text-13 border rounded text-gray-600"
              style={{ borderColor: "#e0e0e0" }}
            />
          </div>

          {/* Hamburger menu for Sort, Filter, Export */}
          <div className="flex items-center space-x-2">
            <button
              onClick={handleExport}
              className="px-2 py-2 text-gray-600 rounded transition-all"
            >
              <img src={Export} alt="Export Icon" className="w-4 h-4" />
            </button>

            <button
              className="px-2 py-2 text-gray-600 rounded transition-all"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
            <div className="bg-white shadow-lg w-full max-w-md h-full">
              <div className="flex justify-between p-6 border-b-2">
                <div className="text-16 font-bold">
                  {t("activityreports.filters.filtersTitle")}
                </div>
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                    src={Close}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between h-[calc(100vh-100px)] px-12 pt-6 overflow-y-auto">
                <div className="flex flex-col gap-[30px]">
                  <div className="flex gap-4">
                    <div className="w-full">
                      <Dropdown
                        label={t("activityreports.filters.selectCustomerId")}
                        options={
                          allCustomer?.map((emp: any) => ({
                            value: emp.customer_id,
                            label: emp.customer_id,
                          })) || []
                        }
                        selectedValue={customerId}
                        onChange={setCustomerID}
                        placeholder={t(
                          "activityreports.filters.selectCustomerId"
                        )}
                        searchable={true}
                      />
                    </div>
                    <div className="w-full">
                      <Dropdown
                        label={t("activityreports.filters.employeeId")}
                        options={
                          employees?.filters?.employees?.map((emp: any) => ({
                            value: emp.emp_id,
                            label: emp.emp_id,
                          })) || []
                        }
                        selectedValue={empId}
                        onChange={setEmpId}
                        placeholder={t(
                          "activityreports.filters.selectEmployeeId"
                        )}
                        searchable={true}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <Dropdown
                        label={t("activityreports.filters.salesOffice")}
                        options={
                          employees?.filters?.sales_offices?.map(
                            (emp: any) => ({
                              value: emp.sales_office_id,
                              label: emp.name,
                            })
                          ) || []
                        }
                        selectedValue={selectedSalesOffice}
                        onChange={setSelectedSalesOffice}
                        placeholder={t(
                          "activityreports.filters.selectSalesOffice"
                        )}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label={t("activityreports.filters.region")}
                        options={regionList.map((sales: any) => ({
                          value: sales,
                          label: sales,
                        }))}
                        selectedValue={selectedRegion}
                        onChange={setSelectedRegion}
                        placeholder={t("activityreports.filters.selectRegion")}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <Dropdown
                        label={t("activityreports.filters.channel")}
                        options={
                          employees?.filters?.channels?.map((emp: any) => ({
                            value: emp.channel_id,
                            label: emp.name,
                          })) || []
                        }
                        selectedValue={channel}
                        onChange={setChannel}
                        placeholder={t("activityreports.filters.selectChannel")}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label={t("activityreports.filters.userType")}
                        options={[
                          {
                            value: "SALESMAN",
                            label: t("activityreports.filters.salesman"),
                          },
                          {
                            value: "PRESELLER",
                            label: t("activityreports.filters.preseller"),
                          },
                        ]}
                        selectedValue={usertype}
                        onChange={setUsertype}
                        placeholder={t(
                          "activityreports.filters.selectUserType"
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <Dropdown
                        label={t("activityreports.filters.routeId")}
                        options={
                          employees?.filters?.territory_routes?.flatMap(
                            (territory: any) =>
                              territory?.routes?.map((route: any) => ({
                                value: String(route),
                                label: `Route ${route}`,
                              }))
                          ) || []
                        }
                        selectedValue={route}
                        onChange={setRoute}
                        placeholder={t("activityreports.filters.selectRouteId")}
                        searchable={true}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label={t("activityreports.filters.territoryId")}
                        options={
                          employees?.filters?.territory_routes?.map(
                            (emp: any) => ({
                              value: String(emp.territory_id),
                              label: emp.territory_id,
                            })
                          ) || []
                        }
                        selectedValue={territory}
                        onChange={setTerritory}
                        placeholder={t(
                          "activityreports.filters.selectTerritoryId"
                        )}
                      />
                    </div>
                  </div>

                  {/* Creation Date */}
                  <div className="date-range-picker space-y-2">
                    <div className="flex gap-4 justify-between w-full">
                      <div className="w-full">
                        <label className="block text-10 text-gray-700 mb-1">
                          {t("activityreports.filters.startDate")}:
                        </label>
                        <input
                          type="date"
                          className="rounded p-2  border-2 border-gray-400 w-full"
                          max={new Date().toISOString().split("T")[0]}
                          value={startDate}
                          onChange={handleStartDateChange}
                        />
                      </div>
                      <div className="w-full">
                        <label className="block text-10 text-gray-700 mb-1">
                          {t("activityreports.filters.endDate")}:
                        </label>
                        <input
                          type="date"
                          className="rounded p-2  border-2 border-gray-400 w-full"
                          max={new Date().toISOString().split("T")[0]}
                          value={endDate}
                          onChange={handleEndDateChange}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Time Frame Selector */}
                  <div>
                    <TimeFrameSelector
                      customClass={"w-full border border-gray-300 rounded p-2"}
                      setSelectTimeFrame={setSelectTimeFrame}
                      timeFrame={timeFrame}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-4">
                  <Button
                    onClick={handleClearFilters}
                    label={t("activityreports.filters.clearFilter")}
                    type="grey"
                  />
                  <Button
                    onClick={() => handleApply(sortType)}
                    label={t("activityreports.filters.applyFilter")}
                    type="purple"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="h-[calc(100vh-200px)] md:h-[calc(100vh-120px)] overflow-auto page-container p-2 pt-0 scrollbar scrollbar-thin">
          <table className="min-w-full bg-white border border-black-200 table-auto border-separate border-spacing-0">
            <thead className="bg-gray-200 sticky top-[0px] z-20">
              <tr>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("activityreports.tableHeaders.visitDate")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.territoryId")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.routeId")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.salesmanId")}
                </th>
                <th className="px-4 py-3 border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.salesmanName")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("activityreports.tableHeaders.customerId")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("activityreports.tableHeaders.customerName")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.salesOffice")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.salesOfficeDescription")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.salesChannel")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.salesDivision")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.checkinTime")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.checkinRequest")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.checkinLocation")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.viewFridgeImages")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.viewFloorImages")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.viewShelfImages")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.invoice")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.purchaseOrder")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.quantity200ml")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.quantity330ml")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.quantity500ml")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.quantity600ml")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.quantity1_5ltr")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.quantity12ltr")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.quantityGlass")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.totalQuantity")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.valueOfInvoicePO")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.codOrder")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.bundleOrder")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.collectionAmount")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.advancePaymentAmount")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.capturedLocation")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.failedVisitReason")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.failedVisitImage")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.checkoutTime")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500">
                  {t("activityreports.tableHeaders.totalVisitTime")}
                </th>
              </tr>
            </thead>
            <tbody>
              {employees?.items?.length > 0 &&
                employees.items.map((employee: any) => (
                  <tr
                    key={employee.emp_id}
                    className="text-sm	hover:bg-gray-50 "
                  >
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.visit_date}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.territory_id || "N/A"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.route_id || "N/A"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.salesman_id || "N/A"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.salesman_name || "N/A"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.customer_id || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.customer_name || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.sales_office_name || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.sales_office_description || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.sales_channel_name || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.sales_division_name || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.check_in_at
                        ? moment
                            .utc(employee.check_in_at, "HH:mm:ss.SSSSSS")
                            .local()
                            .format("hh:mm A")
                        : "--:--"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.check_in_authorization_from_supervisor || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.check_in_location || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {/* {employee.fridge_image|| 0} */}
                      {employee.fridge_image ? (
                        <button onClick={() => handleSetImage(employee.fridge_image ,"Fridge Image")} >
                          <img src={Eye} alt="fridgeimage" />
                        </button>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {/* {employee.floor_image || 0} */}
                      {employee.floor_image ? (
                        <>
                          <button onClick={() => handleSetImage(employee.floor_image ,'Floor Image')}>
                            <img src={Eye} alt="floorimage" />
                          </button>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300  text-black-600 whitespace-nowrap">
                      {employee.cooler_image ? (
                        <>
                          <button onClick={() => handleSetImage(employee.cooler_image ,'Shelf Image')} >
                            <img src={Eye} alt="coolerimage" />
                          </button>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.invoice || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.purchase_order || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.ml_200 || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.ml_330 || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.ml_500 || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.ml_600 || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.ml_1_5ltr || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.ml_12ltr || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.glass || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.total_qty || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.invoice_value || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.cod_order || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.bundle_order || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {formatNumber(employee.collection_amount || 0)}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {formatNumber(employee.advance_payment_amount || 0)}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.captured_location || 0}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.failed_visit_reason ? employee.failed_visit_reason : '-'}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.failed_visit_image ? (
                        <>
                          <button onClick={() => handleSetImage(employee.failed_visit_image ,'Failed visit image')}>
                            <img src={Eye} alt="failed_visit_image" />
                          </button>
                         
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.check_out_at
                        ? moment
                            .utc(employee.check_out_at, "HH:mm:ss.SSSSSS")
                            .local()
                            .format("hh:mm A")
                        : "--:--"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {employee.total_visit_time || 0}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {employees?.items?.length === 0 && !loading && (
            <>
              <div className="px-4 py-3 border text-center text-black-600 w-full flex items-center justify-center">
                {t("planogram.noresultsfound")}
              </div>
            </>
          )}
          {loading && <Loader />}
        </div>
        <div className="sm:hidden bg-white p-2 border-t-2 mt-auto">
          <PaginationComponent
            totalCount={employees?.total}
            itemsPerPage={employees?.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
        {viewImg && (
          <ImageDailog
            imageUrl={imgUrl}
            onClose={() => setViewImg(false)}
            title={imgType}
          />
        )}
      </div>
    </DashboardPage>
  );
};

export default EmployeeTable;
