import React from "react";

interface WorkStatsCardProps {
  workingDays: string;
  title: string;
  textColor?: string;
}

const WorkStatsCard: React.FC<WorkStatsCardProps> = ({
  workingDays,
  title,
  textColor,
}) => {
  return (
    <div className="border border-gray-300  md:max-w-none p-4 w-full h-auto">
      <p className="text-14 text-grey ">{title}</p>
      <p
        className={`text-28  font-bold text-${
          textColor ? `[${textColor}]` : "purple"
        }`}
      >
        {workingDays}
      </p>
    </div>
  );
};

export default WorkStatsCard;
