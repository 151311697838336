import React from "react";
import WorkStatsCard from "../../components/WorkStatsCard";
import { useTranslation } from "react-i18next";

interface ReportProps {
  employeeSchedule: any;
}

const Report: React.FC<ReportProps> = ({ employeeSchedule }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();

  return (
    <div className=" overflow-auto h-auto md:h-[calc(100vh-150px)] scrollbar scrollbar-thin ">
      <div className="grid grid-cols-2 gap-4 p-4">
        <WorkStatsCard
          title="Working days"
          workingDays={employeeSchedule?.working_days + " Days"}
        />
        <WorkStatsCard
          title="Total Working Time"
          workingDays={employeeSchedule?.total_working_time}
        />
        <WorkStatsCard
          title="Total visits"
          workingDays={employeeSchedule?.total_visits}
        />
        <WorkStatsCard
          title="Successful visits"
          textColor="#00C48C"
          workingDays={employeeSchedule?.successful_visits}
        />
        <WorkStatsCard
          title="Failed visits"
          textColor="#FF4D68"
          workingDays={employeeSchedule?.failed_visits}
        />
        <WorkStatsCard
          title="Collection Visits"
          textColor="#FFAA2A"
          workingDays={employeeSchedule?.collection_visits}
        />
        <WorkStatsCard
          title="Total Purchase Order"
          workingDays={employeeSchedule?.total_purchase_orders}
        />
        <WorkStatsCard
          title="Carton Sale"
          workingDays={employeeSchedule?.cartons_sold}
        />
        <WorkStatsCard
          title="Total Collection"
          workingDays={employeeSchedule?.amount_collected}
        />
        <WorkStatsCard
          title="Driving distance"
          workingDays={employeeSchedule?.driving_distances + " km"}
        />
        <WorkStatsCard
          title="Break time"
          workingDays={employeeSchedule?.break_time}
        />
        <WorkStatsCard
          title="Driving time"
          workingDays={employeeSchedule?.driving_time}
        />
      </div>
    </div>
  );
};

export default Report;
