import React from "react";
import Close from "../assets/close-circle.svg";

interface ImageComparisonProps {
  actualImage: string;
  processedImage: string;
  comparisonImage: string;
  imageType: string;
  onClose: () => void;
}

const PlanogramPopup: React.FC<ImageComparisonProps> = ({
  actualImage,
  processedImage,
  onClose,
  imageType,
}) => {
  return (
    <div
      className="fixed inset-0  bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-8 max-w-4xl w-auto h-full md:h-[530px] overflow-auto md:overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center pb-4 border-b">
          <h2 className="text-16 font-bold">Image Comparison</h2>
          <button className="text-2xl font-bold" onClick={onClose}>
            <img src={Close} alt="" />
          </button>
        </div>
        <div className="py-2">
          <h3 className="mb-4 text-13 font-bold text-left">
            Image Type : {imageType}
          </h3>
        </div>
        <div className="flex justify-between w-full  gap-4 flex-col md:flex-row">
          <div className="text-center flex-1">
            <h3 className="mb-4 text-13 font-bold text-left">Actual Image :</h3>
            <img
              src={actualImage}
              alt="Actual"
              className="w-full h-[300px] md:w-[378px] md:h-[339px] object-cover bg-gray-300 rounded-lg"
            />
          </div>
          {processedImage && <>
            <div className="text-center flex-1">
            <h3 className="mb-4 text-13 font-bold text-left">
              Processed Image
            </h3>
            <img
              src={processedImage}
              alt="Processed"
              className="w-full h-[300px] md:w-[378px] md:h-[339px] object-cover bg-gray-300 rounded-lg"
            />
          </div>
          </>}
         
        </div>
      </div>
    </div>
  );
};

export default PlanogramPopup;
