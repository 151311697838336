import React from "react";
import PreviousArrow from "../assets/previous-arrow.svg";
import NextArrow from "../assets/next-arrow.svg";
import { useTranslation } from "react-i18next";
interface PaginationProps {
  totalCount: number;
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: any;
}

const PaginationComponent: React.FC<PaginationProps> = ({
  totalCount,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center">
      <p className="text-13 font-normal">
      {t("pagination.showingpage")} {currentPage} {t("pagination.outof")} {totalPages || 0}
      </p>
      <div className="flex ">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`flex text-13 font-normal items-center px-4 py-2 rounded-md cursor-pointer
            ${currentPage === 1 ? "text-gray-500" : "hover:bg-gray-100"}`}
        >
          <img className="pr-2" src={PreviousArrow} alt="previous" />
          {t("pagination.previous")}
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`flex text-13 font-normal items-center px-4 py-2  rounded-md  cursor-pointer
            ${
              currentPage === totalPages
                ? " text-gray-500"
                : "hover:bg-gray-100"
            }`}
        >
          {t("pagination.next")}
          <img className="pl-2" src={NextArrow} alt="next" />
        </button>
      </div>
    </div>
  );
};

export default PaginationComponent;
