import React from "react";
import { formatNumber, formatNumberWithoutFraction } from "../utility";
import { useTranslation } from "react-i18next";
interface ProgressBarProps {
  firstAmount: number | string;
  secondAmount: number | string;
  unit: number | string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  firstAmount,
  secondAmount,
  unit,
}) => {
  const totalAmount = Number(firstAmount) + Number(secondAmount);
  const firstPercentage = (Number(firstAmount) / totalAmount) * 100;
  const secondPercentage = (Number(secondAmount) / totalAmount) * 100;
  const { t } = useTranslation();

  return (
    <div className="flex h-[90%]  p-4">
      <div className="flex flex-col gap-6 w-full">
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <div className="h-12  w-[10px] bg-[#00E5DE] rounded"></div>
            <div className="flex flex-col gap-1">
              <p>{ t("DashboardAnalytics.preseller")}</p>
              <h1 className="font-bold tex-20">
                {unit ==='SAR' ?  formatNumber(firstAmount) : formatNumberWithoutFraction(firstAmount)} {unit}
              </h1>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="h-12  w-[10px] bg-[#408BFC] rounded"></div>
            <div className="flex flex-col gap-1">
              <p>{ t("DashboardAnalytics.salesman")}</p>
              <h1 className="font-bold tex-20">
                {unit ==='SAR' ?  formatNumber(secondAmount) : formatNumberWithoutFraction(secondAmount)} {unit}
              </h1>
            </div>
          </div>
        </div>
        <div>
          <p className="font-bold">{ t("DashboardAnalytics.totalsales")}</p>
          <h1 className="text-purple text-[25px] font-bold">
            {unit ==='SAR' ?  formatNumber(totalAmount) : formatNumberWithoutFraction(totalAmount)} {unit}
          </h1>
        </div>
      </div>
      <div className="w-10 min-w-[5px] bg-gray-200 rounded relative md:h-full h-[200px]">
        <div
          className="absolute bottom-0 left-0 w-full  bg-[#00E5DE] rounded-t"
          style={{
            height: `${firstPercentage}%`,
            bottom: `${secondPercentage}%`,
          }}
        />
        <div
          className="absolute bottom-0 left-0 w-full bg-[#408BFC] rounded-b"
          style={{ height: `${secondPercentage}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
