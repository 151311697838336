import React, { useEffect, useState } from "react";
import DashboardPage from "../../layout";
import ActionItem from "../../assets/actionItem.svg";
import MenuDots from "../../assets/menuDots.svg";
import Pagination from "../../components/Pagination";
import getCustomerList from "../../api/aging";

const CustomerTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const customersPerPage = 15;
  const [searchQuery, setSearchQuery] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDates, setSelectedDates] = useState<{
    startDate: any;
    endDate: any;
  }>({ startDate: new Date(), endDate: new Date() });
  const handleFilterClick = () => {
    setOpen(true);
  };
  const [open, setOpen] = useState(false);
  const [customers, setCustomersData] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = useState<any>("");

  const handleSearch = () => {
    const filtered = customers.filter((customer: { name: string }) =>
      customer.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setCustomersData(filtered);
    setCurrentPage(1);
  };

  const handleSort = () => {
    console.log("Sort button clicked");
  };

  const handleClose = () => setOpen(false);

  const handleExport = () => {
    console.log("Export button clicked");
  };

  const getCustomerData = async (
    startDate: Date | undefined,
    endDate: Date | undefined,
    searchQuery: string
  ) => {
    const formattedStartDate = startDate
      ? startDate.toISOString().split("T")[0]
      : undefined;
    const formattedEndDate = endDate
      ? endDate.toISOString().split("T")[0]
      : undefined;

    const data = {
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      search: searchQuery,
    };
    const params = {
      page: currentPage,
      size: customersPerPage,
    };

    await getCustomerList(params, data)
      .then((res) => {
        console.log(res, "response");
        setCustomersData(res);
      })
      .catch((error) => {
        console.error("Error fetching Customer data:", error);
      });
  };

  useEffect(() => {
    if (selectedDates.startDate && selectedDates.endDate) {
      getCustomerData(
        selectedDates.startDate,
        selectedDates.endDate,
        searchQuery
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates, searchQuery, currentPage]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const handleApply = (event: React.MouseEvent<HTMLButtonElement>): void => {
    getCustomerData(
      selectedDates.startDate,
      selectedDates.endDate,
      searchQuery
    );
    setOpen(false);
  };

  return (
    <DashboardPage>
      <div className="bg-gray-50 pl-[10px] lg:pl-[15px] md:pl-[15px] sm:pl-0 pr-4">
        <div className="flex justify-between items-center bg-gray-100 p-3 mb-4 sticky top-0 z-5 overflow-x-auto">
          <Pagination
            totalCount={customers?.total}
            itemsPerPage={customersPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />

          <div className="flex space-x-4 items-center">
            <div className="relative flex items-center">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                className="px-4 py-2 text-sm border rounded bg-gray-50 text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
              <button
                onClick={handleSearch}
                className="ml-2 px-4 py-2 text-sm text-gray-600 rounded transition-all"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                Search
              </button>
            </div>

            <button
              onClick={handleSort}
              className="px-4 py-2 text-sm text-gray-600 rounded transition-all"
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              Sort by
            </button>
            <button onClick={handleFilterClick}>Filter</button>

            <button
              onClick={handleExport}
              className="px-4 py-2 text-sm text-gray-600 rounded transition-all"
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              Export
            </button>
          </div>
        </div>

        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end items-start z-50">
            <div className="bg-white shadow-lg p-12 w-full max-w-md h-screen">
              <div className="text-lg font-semibold text-gray-800">Filters</div>
              <div className="mt-4 space-y-4">
                {/* Employee Name */}
                <div>
                  <label className="block text-gray-700 mb-1">
                    Employee Name
                  </label>
                  <select className="w-full border border-gray-300 rounded p-2">
                    <option value={""}>All Employee</option>
                    {customers?.filters?.employees.map((emp: any) => {
                      return <option value={emp.emp_id}>{emp.name_eng}</option>;
                    })}
                  </select>
                </div>

                {/* Employee ID */}
                <div>
                  <label className="block text-gray-700 mb-1">
                    Employee Id
                  </label>
                  <select className="w-full border border-gray-300 rounded p-2">
                    <option value={""}>All Employee Id</option>
                    {customers?.filters?.employees.map((emp: any) => {
                      return <option value={emp.emp_id}>{emp.emp_id}</option>;
                    })}
                  </select>
                </div>

                {/* Sales Office and Region */}
                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <label className="block text-gray-700 mb-1">
                      Sales Office
                    </label>
                    <select className="w-full border border-gray-300 rounded p-2">
                      <option value={""}>All Sales Office</option>
                      {customers?.filters?.sales_offices.map((sales: any) => {
                        return (
                          <option value={sales.sales_office_id}>
                            {sales.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="w-1/2">
                    <label className="block text-gray-700 mb-1">Division</label>
                    <select className="w-full border border-gray-300 rounded p-2">
                      <option value={""}>All division</option>
                      {customers?.filters?.divisions.map((sales: any) => {
                        return (
                          <option value={sales.division_id}>
                            {sales.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {/* Payment Term and Status */}
                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <label className="block text-gray-700 mb-1">Channels</label>
                    <select className="w-full border border-gray-300 rounded p-2">
                      <option value={""}>All channel</option>
                      {customers?.filters?.channels.map((sales: any) => {
                        return (
                          <option value={sales.channel_id}>{sales.name}</option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="w-1/2">
                    <label className="block text-gray-700 mb-1">Status</label>
                    <select
                      className="w-full border border-gray-300 rounded p-2"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value={""}>Select status</option>
                      <option value={"A"}>Active</option>
                      <option value={"I"}>Inactive</option>
                    </select>
                  </div>
                </div>

                {/* Creation Date */}
                <div className="date-range-picker space-y-2">
                  {/* <label className="block text-gray-700">Creation Date:</label> */}
                  <div className="flex space-x-4">
                    <div>
                      <label>Start Date:</label>
                      <input
                        type="date"
                        className="border border-gray-300 rounded p-1"
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </div>
                    <div>
                      <label>End Date:</label>
                      <input
                        type="date"
                        className="border border-gray-300 rounded p-1"
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="flex justify-between mt-4">
                <button
                  onClick={handleClose}
                  className="px-4 py-2 text-white bg-gray-400 rounded transition duration-200 hover:bg-gray-500"
                >
                  Cancel
                </button>
                <button
                  onClick={handleApply}
                  className="px-4 py-2 text-white bg-blue-500 rounded transition duration-200 hover:bg-blue-600"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="h-[calc(100vh-150px)] overflow-auto  page-container">
          <table className="min-w-full bg-white border border-black-200 table-auto">
            <thead className="bg-gray-200 sticky top-[0px] z-20">
              <tr>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Customer Code
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Customer Name
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Payment Term
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Description
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Channel
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Division
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Sales Office
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Credit Limit
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Department
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Comment 1
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Comment 2
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Not Due
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  1-30 Days
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  31-60 Days
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  61-90 Days
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  91-120 Days
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  121-150 Days
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  151-180 Days
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  181-270 Days
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  271-360 Days
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  More Than
                  <br />
                  361
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Total
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Total Due
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Last Invoice
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Last Invoice
                  <br />
                  Date
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Last Invoice
                  <br />
                  Time
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Last Payment
                  <br />
                  Document
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Last Document
                  <br />
                  Date
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Last Posting
                  <br />
                  Date
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap">
                  Last Payment
                  <br />
                  Amount
                </th>

                <th className="sticky right-0 bg-gray-200 px-4 py-3 border text-left text-sm font-bold text-black-500 tracking-wider z-4">
                  Action
                  <br />
                  Item
                </th>
              </tr>
            </thead>
            <tbody>
              {customers?.items?.length > 0 ? (
                customers.items.map((customer: any) => (
                  <tr key={customer.emp_id} className="text-sm	hover:bg-gray-50">
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.customer_code}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.customer_name}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.payment_term}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.payment_description}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.channel}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.division}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.sales_office}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.credit_limit}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.department}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.comment_1}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.comment_2}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.not_due}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.days_1_30}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.days_31_60}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.days_61_90}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.days_91_120}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.days_121_150}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.days_151_180}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.days_181_270}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.days_271_360}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.more_than_361}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.total}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.total_due}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_invoice}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_invoice_date}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_invoice_time}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_payment_document}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_document_date}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_posting_date}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.last_payment_amount}
                    </td>

                    <td className="sticky right-0 bg-white px-4 py-2">
                      <div className="flex items-center space-x-2">
                        <div className="cursor-pointer">
                          <img
                            src={ActionItem}
                            alt="Action Item"
                            className="h-6 w-6"
                          />
                        </div>
                        <div className="cursor-pointer">
                          <img
                            src={MenuDots}
                            alt="Menu Dots"
                            className="h-6 w-6"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={50}
                    className="px-4 py-3 border text-center text-black-600"
                  >
                    No customers found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardPage>
  );
};

export default CustomerTable;
