import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../pages/login/index";
import SignUp from "../pages/login/signUp";
import Salesman from "../pages/salesman/Salesman";
import ErrorPage from "../pages/common/error";
import URL_MAPPING from "./constants";
import CustomerTable from "../pages/employee/index";
import Dashboard from "../pages/dashboard/index";
import CustomerDetails from "../pages/customer_details/customer_details";
import TerritoryPlanner from "../pages/territory";
import ResetPass from "../pages/login/resetPass";
import GoogleAuth from "../pages/login/googleAuth";
import Landing from "../pages/landing/index";
import Cookies from "js-cookie";
import DashboardAnalytics from "../pages/dashboard_analytics/index";
import EmployeeTable from "../pages/employee/index";
import MasterList from "../pages/customer_details/masterList";
import Planogram from "../pages/planogram/index";
import Notifications from "../pages/notification_screen/index";
import VisitReport from "../pages/visit_report/index";

import AgingReport from "../pages/aging_report/index";
interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  [key: string]: any; // To accept any additional props
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isAuthenticated = Cookies.get("token");

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  return <Component {...rest} />;
};

const AllRoutes: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path={URL_MAPPING.LOGIN} element={<Login />} />
      <Route path={URL_MAPPING.LOGIN_ROOT} element={<Login />} />
      <Route path={URL_MAPPING.SIGNUP} element={<SignUp />} />
      <Route path={URL_MAPPING.RESETPASS} element={<ResetPass />} />
      <Route path={URL_MAPPING.GOOGLEAUTH} element={<GoogleAuth />} />
      <Route path="*" element={<ErrorPage />} />

      {/* protected routes */}
      <Route
        path={URL_MAPPING.ANALYTICS}
        element={<ProtectedRoute component={DashboardAnalytics} />}
      />
      <Route
        path={URL_MAPPING.SALESMAN}
        element={<ProtectedRoute component={Salesman} />}
      />
      <Route
        path={URL_MAPPING.ACTIVITYREPORT}
        element={<ProtectedRoute component={CustomerTable} />}
      />
      <Route
        path={URL_MAPPING.DASHBOARD}
        element={<ProtectedRoute component={Dashboard} />}
      />
      <Route
        path={URL_MAPPING.CUSTOMERLISTDETAILS}
        element={<CustomerDetails />}
      />
      <Route
        path={URL_MAPPING.TERRITORIES}
        element={<ProtectedRoute component={TerritoryPlanner} />}
      />

      <Route
        path={URL_MAPPING.LANDING}
        element={<ProtectedRoute component={Landing} />}
      />
      <Route
        path={URL_MAPPING.AGINGREPORT}
        element={<ProtectedRoute component={AgingReport} />}
      />

      <Route
        path={URL_MAPPING.EMPLOYEETABLE}
        element={<ProtectedRoute component={EmployeeTable} />}
      />

      <Route
        path={URL_MAPPING.MASTERLIST}
        element={<ProtectedRoute component={MasterList} />}
      />

      <Route
        path={URL_MAPPING.PLANOGRAM}
        element={<ProtectedRoute component={Planogram} />}
      />
      <Route
        path={URL_MAPPING.NOTIFICATIONS}
        element={<ProtectedRoute component={Notifications} />}
      />
      <Route
        path={URL_MAPPING.VISITREPORT}
        element={<ProtectedRoute component={VisitReport} />}
      />
    </Routes>
  );
};

export default AllRoutes;
