import React from "react";
import HorizontalBar from "../../components/HorizontalBar";
import HorizontalThreeBar from "../../components/HorizontalThreeBar";
import GuageChart from "../../components/GuageChart";
import VerticalProgressBar from "../../components/VerticalProgressBar";
import { formatNumber, formatNumberWithoutFraction } from "../../utility";
import ActiveMember from "../../assets/active-member.svg";
import InActiveMember from "../../assets/inactive-member.svg";
import OnLeave from "../../assets/on-leave.svg";
import { useTranslation } from "react-i18next";

interface ChannelProps {
  dashobardData: any;
}

const Channel: React.FC<ChannelProps> = ({ dashobardData }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="md:w-full  overflow-x-hidden  h-auto lg:h-[calc(100vh-160px)]  lg:overflow-y-scroll flex flex-col gap-2 pr-2 scrollbar scrollbar-thin ">
        <div className="flex justify-between gap-2  w-full flex-col md:flex-row bg-[#F5F5F5] p-2 rounded-md">
          <div className="bg-white border-2 border-purple p-[20px] flex flex-col w-full rounded">
            <div className="text-left">
              <h2 className="text-13 font-bold ">
                {t("DashboardAnalytics.teamCount")}
              </h2>
            </div>
            <div className="flex justify-center gap-3 items-center pt-1">
              <div className="flex flex-col w-full rounded ">
                <div className="flex gap-2 items-center justify-between">
                  <p className="font-normal text-13">
                    {t("DashboardAnalytics.preseller")}
                  </p>
                  <p className="text-xl font-bold">
                    {formatNumberWithoutFraction(
                      dashobardData?.team_metrics?.total_preseller_count || 0
                    )}
                  </p>
                </div>
                <div className="flex gap-2 items-center justify-between">
                  <p className="font-normal text-13">
                    {t("DashboardAnalytics.salesman")}
                  </p>
                  <p className="text-xl font-bold">
                    {formatNumberWithoutFraction(
                      dashobardData?.team_metrics?.total_salesman_count || 0
                    )}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center min-w-[80px] pl-3 border-l-2">
                {/* <div className="h-12 w-[6px] rounded-lg bg-purple mr-2"></div> */}
                <p className="text-13  font-normal	">
                  {t("DashboardAnalytics.total")}
                </p>
                <p className="text-purple text-[39px] font-bold">
                  {formatNumberWithoutFraction(
                    dashobardData?.team_metrics?.total_team_count || 0
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className=" bg-green-50 p-[20px] w-full rounded border-cugreen border-2">
            <div className="text-left flex flex-col justify-between">
              <h2 className="text-green-600 text-base font-semibold">
                {t("DashboardAnalytics.activeMembers")}
              </h2>
              <div className="flex justify-between items-center pt-1">
                <p className=" text-green-600 text-[39px] font-bold mt-2">
                  {formatNumberWithoutFraction(
                    dashobardData?.team_metrics?.total_active_count || 0
                  )}
                </p>
                <img src={ActiveMember} alt="" />
              </div>
            </div>
          </div>

          <div className=" bg-red-50 p-[20px] w-full rounded  border-[#FF4D68] border-2">
            <div className="text-left flex justify-between flex-col">
              <h2 className="text-red-600 text-base	  font-semibold">
                {t("DashboardAnalytics.inactiveMembers")}
              </h2>

              <div className="flex justify-between pt-1 items-center">
                <p className="text-[39px] text-[#FF4D68] font-bold">
                  {formatNumberWithoutFraction(
                    dashobardData?.team_metrics?.total_inactive_count || 0
                  )}
                </p>
                <img src={InActiveMember} alt="" />
              </div>
            </div>
          </div>

          <div className="bg-blue-50 p-[20px] w-full rounded border-2 border-[#408BFC]">
            <div className="text-left flex justify-between flex-col">
              <h2 className="text-blue-600 text-base font-semibold">
                {t("DashboardAnalytics.employeesOnLeave")}
              </h2>
            </div>
            <div className="flex justify-between pt-1 items-center">
              <p className=" text-blue-600 text-[39px] font-bold">
                {formatNumberWithoutFraction(
                  dashobardData?.team_metrics?.total_on_leave_count || 0
                )}
              </p>
              <img src={OnLeave} alt="" />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 bg-[#F5F5F5] p-2">
            <div className="bg-white p-4 border-2 flex flex-col ">
              <h2 className="text-13 font-bold mb-4">
                {t("DashboardAnalytics.numberOfUniqueAccountsServed")}
              </h2>
              <div className="flex justify-between">
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col items-start">
                    <div className="text-[31px] font-bold">
                      {formatNumberWithoutFraction(
                        dashobardData?.visit_metrics
                          ?.unique_accounts_served_count || 0
                      )}
                      <span className="text-[20px] font-semibold">
                        /{" "}
                        {formatNumberWithoutFraction(
                          dashobardData?.visit_metrics
                            ?.unique_accounts_target_count || 0
                        )}
                      </span>
                    </div>
                    <div className="text-13 font-semibold">
                      {t("DashboardAnalytics.accountsServed")}
                    </div>
                  </div>
                  <div className="flex flex-col items-start mt-4">
                    <div className="text-[25px] font-bold text-red-500">
                      {formatNumberWithoutFraction(
                        dashobardData?.visit_metrics
                          ?.unique_accounts_pending_count || 0
                      )}
                    </div>
                    <div className="text-13 font-semibold">
                      {t("DashboardAnalytics.pendingAccounts")}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center w-[75%]">
                  <div className=" w-full flex flex-col items-center">
                    <GuageChart
                      served={
                        dashobardData?.visit_metrics
                          ?.unique_accounts_served_count || 0
                      }
                      pending={
                        dashobardData?.visit_metrics
                          ?.unique_accounts_pending_count || 0
                      }
                      target={
                        dashobardData?.visit_metrics
                          ?.unique_accounts_target_count || 0
                      }
                      value={
                        Number(
                          dashobardData?.visit_metrics
                            ?.unique_accounts_served_count
                        ) /
                        Number(
                          dashobardData?.visit_metrics
                            ?.unique_accounts_target_count
                        )
                      }
                      labelValue={
                        dashobardData?.visit_metrics
                          ?.unique_accounts_served_count || 0
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-4">
                <div className="flex items-center mx-4 gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#00C48C" }}
                  ></div>
                  <span className=" text-xs">
                    {t("DashboardAnalytics.accountsServed")}
                  </span>
                </div>
                <div className="flex items-center mx-4 gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#FF4D68" }}
                  ></div>
                  <span className=" text-xs">
                    {t("DashboardAnalytics.pendingAccounts")}
                  </span>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 border-2 flex flex-col justify-between">
              <h2 className="text-13 font-bold mb-4">
                {t("DashboardAnalytics.salesAchievement")}
              </h2>
              <div className="flex justify-between">
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col items-start">
                    <div className="text-[25px] font-bold">
                      {formatNumberWithoutFraction(
                        dashobardData?.visit_metrics
                          ?.total_cartons_sold_count || 0
                      )}
                    </div>
                    <div className="text-13 font-semibold">
                      {t("DashboardAnalytics.cartons")}
                    </div>
                  </div>
                  <div className="flex flex-col items-start mt-4">
                    <div className="text-[25px] font-bold text-red-500">
                      {formatNumberWithoutFraction(
                        dashobardData?.visit_metrics
                          ?.total_cartons_target_count || 0
                      )}
                    </div>
                    <div className="text-13 font-semibold">
                      {t("DashboardAnalytics.targetCartons")}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center w-[75%]">
                  <div className="w-full flex flex-col items-center">
                    <GuageChart
                      served={
                        dashobardData?.visit_metrics
                          ?.total_cartons_sold_count || 0
                      }
                      pending={
                        dashobardData?.visit_metrics?.pending_cartons_count || 0
                      }
                      target={
                        dashobardData?.visit_metrics
                          ?.total_cartons_target_count || 0
                      }
                      value={
                        dashobardData?.visit_metrics
                          ?.total_cartons_sold_count &&
                        Number(
                          dashobardData?.visit_metrics?.total_cartons_sold_count
                        ) /
                          Number(
                            dashobardData?.visit_metrics
                              ?.total_cartons_target_count
                          )
                      }
                      labelValue={
                        dashobardData?.visit_metrics
                          ?.total_cartons_sold_count || 0
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <div className="flex items-center mx-4 gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#00C48C" }}
                  ></div>
                  <span className=" text-xs">
                    {t("DashboardAnalytics.actualSales")}
                  </span>
                </div>
                <div className="flex items-center mx-4 gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#FF4D68" }}
                  ></div>
                  <span className=" text-xs">
                    {t("DashboardAnalytics.pendingSales")}
                  </span>
                </div>
              </div>
            </div>
          </div>

        <div className="flex gap-3 lg:flex-row flex-col bg-[#F5F5F5] p-2 flex-wrap xl:flex-nowrap">
          <div className="flex gap-3 bg-[#F5F5F5] rounded flex-col lg:flex-row lg:w-full xl:w-[60%]">
            <div className="bg-white p-4 border-2 rounded lg:w-[50%]">
              <h2 className="text-13 font-bold mb-4">
                {t("DashboardAnalytics.percentageOfGlassInvoices")}
              </h2>
              <div className="flex flex-row justify-between">
                <div className="w-[95%] flex flex-col items-center">
                  <GuageChart
                    value={Number(
                      dashobardData?.visit_metrics?.glass_invoice_percentage
                    )}
                    target={100}
                    labelValue={
                      Number(
                        dashobardData?.visit_metrics?.glass_invoice_percentage ) * 100 || 0
                    }
                  />
                </div>
              </div>
            </div>
            <div className="bg-white justify-between p-4 border-2 flex flex-col lg:w-[50%]">
              <h2 className="text-13 font-bold mb-4">
                {t("DashboardAnalytics.totalGlassQuantity")}
              </h2>
              <div className="flex justify-between">
                <div className="flex flex-col justify-center">
                  <div className="flex flex-col items-start">
                    <div className="text-[25px] font-bold">
                      {formatNumberWithoutFraction(
                        dashobardData?.visit_metrics
                          ?.total_glass_cartons_sold_count || 0
                      )}{" "}
                    </div>
                    <div className="text-13 font-semibold">
                      {t("DashboardAnalytics.cartons")}
                    </div>
                  </div>
                  <div className="flex flex-col items-start mt-4">
                    <div className="text-[25px] font-bold text-red-500">
                      {formatNumberWithoutFraction(
                        dashobardData?.visit_metrics
                          ?.total_cartons_sold_count || 0
                      )}
                    </div>
                    <div className="text-13 font-semibold">
                      {t("DashboardAnalytics.totalCartons")}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center w-[75%]">
                  <div className="w-full flex flex-col items-center">
                    <GuageChart
                      target={
                        dashobardData?.visit_metrics
                          ?.total_cartons_sold_count || 0
                      }
                      value={
                        Number(
                          dashobardData?.visit_metrics
                            ?.total_glass_cartons_sold_count
                        ) /
                          Number(
                            dashobardData?.visit_metrics
                              ?.total_cartons_sold_count
                          ) || 0
                      }
                      labelValue={
                        dashobardData?.visit_metrics
                          ?.total_glass_cartons_sold_count || 0
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <div className="flex items-center mx-4 gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#00C48C" }}
                  ></div>
                  <span className=" text-xs">
                    {t("DashboardAnalytics.actualSales")}
                  </span>
                </div>
                <div className="flex items-center mx-4 gap-2">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: "#FF4D68" }}
                  ></div>
                  <span className=" text-xs">
                    {t("DashboardAnalytics.totalSales")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 border-2 lg:w-full xl:w-[40%] flex flex-col md:w-full">
            <h2 className="text-13 font-bold mb-4">
              {t("DashboardAnalytics.uniqueAccountsSoldGlassItems")}
            </h2>
            <div className="flex justify-between">
              <div className="flex flex-col justify-center">
                <div className="flex flex-col items-start">
                  <div className="text-[31px] font-bold flex items-center ">
                    {formatNumberWithoutFraction(
                      dashobardData?.visit_metrics
                        ?.total_glass_unique_customers || 0
                    )}{" "}
                  </div>
                  <div className="text-13 font-semibold">
                    {t("DashboardAnalytics.accountsServed")}
                  </div>
                </div>
                <div className="flex flex-col items-start mt-4">
                  <div className="text-3xl font-bold text-red-500">
                    {formatNumberWithoutFraction(
                      dashobardData?.visit_metrics
                        ?.unique_accounts_target_count || 0
                    )}
                  </div>
                  <div className="text-13 font-semibold">
                    {t("DashboardAnalytics.totalAccounts")}
                  </div>
                </div>
              </div>
              <div className="flex justify-center w-[75%]">
                <div className="w-full flex flex-col items-center">
                  <GuageChart
                    target={
                      dashobardData?.visit_metrics
                        ?.unique_accounts_target_count || 0
                    }
                    value={
                      Number(
                        dashobardData?.visit_metrics
                          ?.total_glass_unique_customers
                      ) /
                        Number(
                          dashobardData?.visit_metrics
                            ?.unique_accounts_target_count
                        ) || 0
                    }
                    labelValue={
                      dashobardData?.visit_metrics
                        ?.total_glass_unique_customers || 0
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-4">
              <div className="flex items-center mx-4 gap-2">
                <div
                  className="h-4 w-4 rounded-full"
                  style={{ backgroundColor: "#00C48C" }}
                ></div>
                <span className=" text-xs">
                  {t("DashboardAnalytics.accountsServed")}
                </span>
              </div>
              <div className="flex items-center mx-4 gap-2">
                <div
                  className="h-4 w-4 rounded-full"
                  style={{ backgroundColor: "#FF4D68" }}
                ></div>
                <span className=" text-xs">
                  {t("DashboardAnalytics.pendingAccounts")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col md:flex-col lg:flex-row gap-2">
          <div className="flex gap-2 flex-col md:w-full">
            <div className="flex gap-3 w-full">
              <div className="w-[70%] flex flex-col gap-3">
                <div className="flex gap-4 bg-[#F5F5F5] w-full p-2 rounded flex-col md:flex-row">
                  <div className="bg-white p-4 border-2 flex flex-col w-full md:w-1/2 min-h-[250px] rounded flex-1">
                    <h2 className="text-13 font-bold mb-4">
                      {t("DashboardAnalytics.averageSKUInvoice")}
                    </h2>
                    <div className="flex flex-row justify-between">
                      <div className="w-[95%] flex flex-col items-center">
                        <GuageChart
                          value={
                            Number(
                              Math.round(
                                dashobardData?.visit_metrics
                                  ?.average_sku_per_invoice
                              )
                            ) / 4
                          }
                          target={4}
                          labelValue={
                            Math.round(
                              dashobardData?.visit_metrics
                                ?.average_sku_per_invoice
                            ) || 0
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border border-gray-300 rounded bg-white w-full min-h-[250px] p-4 flex-1">
                    <h2 className="text-13 font-bold">
                      {t("DashboardAnalytics.bundleOrders")}
                    </h2>
                    <div className="flex justify-between items-center mb-2">
                      <div className="pl-0 p-4 text-13">
                        <p>{t("DashboardAnalytics.totalOrders")}</p>
                        <p className="font-bold text-3xl">
                          {formatNumberWithoutFraction(
                            dashobardData?.visit_metrics
                              ?.total_bundle_orders_count || 0
                          )}
                        </p>
                      </div>
                      <div className="border-2 w-[2px] h-12"></div>
                      <div className="border-gray-300 p-4 text-13">
                        <p>{t("DashboardAnalytics.cartonQuantity")}</p>
                        <p className="font-bold text-3xl">
                          {formatNumberWithoutFraction(
                            dashobardData?.visit_metrics?.bundle_cartons_qty ||
                              0
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="border-t border-gray-300 rounded p-4 pl-0">
                      <p className="text-13 font-medium">
                        {t("DashboardAnalytics.orderValue")}
                      </p>
                      <p className="text-3xl font-bold text-purple">
                        {formatNumber(
                          dashobardData?.visit_metrics
                            ?.total_bundle_order_value || 0
                        ) + " "}
                        SAR
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex gap-3">
                    <div className="w-full flex flex-col gap-3">
                      <div className="flex gap-4 bg-[#F5F5F5] w-full p-2 rounded flex-col md:flex-row">
                        <div className="border-2 w-full p-2 rounded bg-white">
                          <div className="px-3 p-2 font-bold text-13">
                            {t("DashboardAnalytics.totalCartonSales")}
                          </div>
                          <VerticalProgressBar
                            firstAmount={
                              dashobardData?.visit_metrics
                                ?.preseller_cartons_sold_count || 0
                            }
                            secondAmount={
                              dashobardData?.visit_metrics
                                ?.salesman_cartons_sold_count || 0
                            }
                            unit={t("DashboardAnalytics.cartons")}
                          />
                        </div>
                        <div className="border-2 w-full p-2 rounded bg-white">
                          <div className="px-3 p-2 font-bold text-13">
                            {t("DashboardAnalytics.totalSARSales")}
                          </div>
                          <VerticalProgressBar
                            firstAmount={
                              dashobardData?.visit_metrics
                                ?.preseller_sale_value || 0
                            }
                            secondAmount={
                              dashobardData?.visit_metrics
                                ?.salesman_sale_value || 0
                            }
                            unit="SAR"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex gap-4 bg-[#F5F5F5] flex-col  w-full md:w-[30%] p-2  rounded">
                <div className="border border-gray-300 rounded p-4 bg-white">
                  <h2 className="text-13 font-bold">
                    {t("DashboardAnalytics.totalInvoicePOGenerated")}
                  </h2>
                  <div className="pt-4">
                    <HorizontalBar
                      invoiceValue={formatNumberWithoutFraction(
                        dashobardData?.visit_metrics?.total_invoices_count || 0
                      )}
                      poValue={formatNumberWithoutFraction(
                        dashobardData?.visit_metrics
                          ?.total_purchase_orders_count || 0
                      )}
                      firstName="Invoice"
                      secondName="PO"
                    />
                  </div>
                </div>
                <div className="bg-white p-4 border-2 flex flex-col rounded">
                  <h2 className="text-13 font-bold">
                    {t("DashboardAnalytics.collectedAmount")}
                  </h2>{" "}
                  <div className="flex flex-row justify-between">
                    <p className="font-bold text-3xl text-green-400 pt-2">
                      {formatNumber(
                        dashobardData?.visit_metrics?.total_collected_amount ||
                          0
                      )}{" "}
                      SAR
                    </p>
                  </div>
                  <div className="border-t-2 flex justify-between pt-2">
                    {/* todo */}
                    <div>
                      <p className="text-16 font-bold">
                        {" "}
                        {formatNumber(
                          dashobardData?.visit_metrics
                            ?.total_collected_amount_by_card || 0
                        )}{" "}
                        SAR
                      </p>
                      <p className="text-13 font-semibold">
                        {t("DashboardAnalytics.byCard")}
                      </p>
                    </div>
                    <div className="border-l-2 pl-8">
                      <p className="text-16 font-bold">
                        {" "}
                        {formatNumber(
                          dashobardData?.visit_metrics
                            ?.total_collected_amount_by_cash || 0
                        )}{" "}
                        SAR
                      </p>
                      <p className="text-13 font-semibold">
                        {t("DashboardAnalytics.byCash")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border border-gray-300 rounded p-4 bg-white">
                  <h2 className="text-13 font-bold">
                    {t("DashboardAnalytics.newCustomerCreated")}
                  </h2>
                  <div className="mt-4">
                    <HorizontalThreeBar
                      firstValue={formatNumberWithoutFraction(
                        dashobardData?.visit_metrics
                          ?.new_customers_created_approved_count || 0
                      )}
                      secondValue={formatNumberWithoutFraction(
                        dashobardData?.visit_metrics
                          ?.new_customers_created_count || 0
                      )}
                      ThirdValue={formatNumberWithoutFraction(
                        dashobardData?.visit_metrics
                          ?.new_customers_created_rejected_count || 0
                      )}
                      // todo
                      firstName={t("DashboardAnalytics.approved")}
                      secondName={t("DashboardAnalytics.underApproval")}
                      thirdName={t("DashboardAnalytics.rejected")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-3 bg-[#F5F5F5] p-2">
          <div className="bg-white w-full p-4 border-2 flex justify-between items-center  min-h-[120px] rounded flex-wrap">
            <h2 className="text-13 font-bold">
              {" "}
              {t("DashboardAnalytics.Average")}
              <br />
              {t("DashboardAnalytics.averageCartonsPerRoute")}
            </h2>
            <p className="text-purple font-bold  text-[39px]">
              {formatNumber(
                dashobardData?.visit_metrics?.average_carton_per_route || 0
              )}
            </p>
          </div>
          <div className="bg-white w-full p-4 border-2 flex justify-between items-center  min-h-[120px] rounded flex-wrap">
            <h2 className="text-13 font-bold">
              {" "}
              {t("DashboardAnalytics.Average")}
              <br />
              {t("DashboardAnalytics.averageInvoicePerCustomer")}
            </h2>
            <p className="text-purple font-bold  text-[39px]">
              {formatNumber(
                dashobardData?.visit_metrics?.average_invoice_per_customer || 0
              )}
            </p>
          </div>
          <div className="bg-white w-full p-2 border-2 flex justify-between rounded items-center px-4 min-h-[120px] flex-wrap">
            <h2 className="text-13 font-bold">
              {t("DashboardAnalytics.dropSize")}
            </h2>{" "}
            <p className="text-purple font-bold text-[39px]">
              {formatNumber(dashobardData?.visit_metrics?.drop_size_count || 0)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Channel;
