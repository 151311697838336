import React, { useState } from "react";
import JourneyPlan from "./JourneyPlan";
import Report from "./Report";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

interface ScheduleProps {
  employeeSchedule: any;
  handleDateChange: any;
  selectedDate: any;
  loader: boolean;
  routeForSelectedCustomer: any;
  setRouteForSelectedCustomer: any;
}

const ButtonGroup: React.FC<ScheduleProps> = ({
  employeeSchedule,
  handleDateChange,
  selectedDate,
  loader,
  routeForSelectedCustomer,
  setRouteForSelectedCustomer
}) => {
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState("Journey Plan");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const buttons = [
    t("ButtonGroup.button_journey_plan"),
    t("ButtonGroup.button_report"),
  ];

  const handleJourney = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <div className="w-full">
      <div className="flex gap-2 pl-4 pr-4 pt-1 pb-1 h-auto border-b-2 flex-wrap hidden md:flex">
        {buttons.map((button) => (
          <button
            onClick={() => setActiveButton(button)}
            className={`px-4 text-13 py-2  focus:outline-none transition-all duration-200 ease-in-out h-max font-semibold ${
              activeButton === button
                ? "bg-cyan text-white active:bg-blue-700"
                : "bg-lightgrey hover:bg-gray-300 active:bg-gray-700"
            }`}
          >
            {button}
          </button>
        ))}
        <div className="flex flex-col max-w-[120px]">
          <label className="text-grey text-10 font-medium ">{t('visit.select_date')}</label>
          <input
            className="font-semibold text-13"
            type="date"
            onChange={handleDateChange}
            value={selectedDate}
          />
        </div>
      </div>
      <div className="w-full md:hidden p-4">
        <button
          className="w-full flex items-center justify-between p-2 border rounded-md shadow-sm  font-semibold p-4"
          onClick={handleJourney}
        >
          <span>{t('visit.journey_plan')}</span>
          <span className="ml-2">
            {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        </button>
      </div>

      <div>
        <div className="hidden md:block">
          {activeButton === "Journey Plan" && (
            <>
              <JourneyPlan
                employeeSchedule={employeeSchedule}
                loader={loader}
                setRouteForSelectedCustomer={setRouteForSelectedCustomer}
                routeForSelectedCustomer={routeForSelectedCustomer}
              />
            </>
          )}
          {activeButton === "Report" && (
            <>
              <Report employeeSchedule={employeeSchedule?.employee_details} />
            </>
          )}
        </div>
        <div className="md:hidden">
          {isDropdownOpen && (
            <>
              <JourneyPlan
                employeeSchedule={employeeSchedule}
                loader={loader}
                setRouteForSelectedCustomer={setRouteForSelectedCustomer}
                routeForSelectedCustomer={routeForSelectedCustomer}

              />
            </>
          )}
          <div className="text-16 font-bold p-4 px-6">Report</div>
          <Report employeeSchedule={employeeSchedule?.employee_details} />
        </div>
      </div>
    </div>
  );
};

export default ButtonGroup;
